import { BaseModel } from "../../model/shared/BaseModel";
import {
  IMPORT_MAPPING_URL,
  WORKFLOW_URL,
} from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";
import { ICopywriterData } from "./components/WorkflowStep5";
import { IWorkflow } from "./dto/IWorkflow";

export const WorkflowService = {
  createWorkflow: (params: IWorkflow) =>
    requests.post(`${WORKFLOW_URL}`, params),
  getAllWorkflows: (params: any): Promise<BaseModel<IWorkflow[]>> =>
    requests.get(`${WORKFLOW_URL}`, params),
  getWorkflowProducts: (
    params: any,
    id: number
  ): Promise<BaseModel<IWorkflow[]>> =>
    requests.get(`${WORKFLOW_URL}/base-data/list/${id}`, params),
  getCopywritingProducts: (
    params: any,
    id: number
  ): Promise<BaseModel<IWorkflow[]>> =>
    requests.get(`${WORKFLOW_URL}/copywriting/list/${id}`, params),
  getNextAttributesWorkflow: (workflowId: number, stepId: string) =>
    requests.get(
      `${WORKFLOW_URL}/${
        stepId === "COPY_WRITING" ? "copywriting" : "attributes"
      }/next/${workflowId}`
    ),
  getNextBaseDataWorkflow: (workflowId: number) =>
    requests.get(`${WORKFLOW_URL}/base-data/next/${workflowId}`),
  getWorkflowAttributesProducts: (
    params: any,
    id: number
  ): Promise<BaseModel<IWorkflow[]>> =>
    requests.get(`${WORKFLOW_URL}/attributes/list/${id}`, params),
  getWorkflowById: (id: number) => requests.get(`${WORKFLOW_URL}/${id}`),
  getWorkflowCharts: (id: number) =>
    requests.get(`${WORKFLOW_URL}/overview/${id}`),
  updateWorkflowById: (id: number, params: IWorkflow) =>
    requests.put(`${WORKFLOW_URL}/${id}`, params),
  deleteWorkflowById: (id: number) => requests.delete(`${WORKFLOW_URL}/${id}`),
  workflowUpload: (file: any, id: number) =>
    requests.put(`${WORKFLOW_URL}/${id}/upload`, { file, id }),
  getNextWorkflow: (workflowId: number) =>
    requests.get(`${WORKFLOW_URL}/base-data/next/${workflowId}`),
  deleteWorkflowBaseData: (productId: number, workflowId: number) =>
    requests.delete(
      `${WORKFLOW_URL}/base-data/delete/${productId}/${workflowId}`
    ),
  deleteAttributeBaseDataById: (productId: number, workflowId: number) =>
    requests.delete(
      `${WORKFLOW_URL}/attributes/delete/${productId}/${workflowId}`
    ),
  saveWorkflowImportById: (id: number) =>
    requests.put(`${WORKFLOW_URL}/import/${id}`),
  assignCopywriter: (id: number, payload: ICopywriterData) =>
    requests.put(`${WORKFLOW_URL}/copywriting/assign-multiple/${id}`, payload),
  saveWorkflowBaseDataById: (id: number) =>
    requests.put(`${WORKFLOW_URL}/base-data/save/${id}`),
  saveWorkflowAttributesById: (id: number) =>
    requests.put(`${WORKFLOW_URL}/attributes/save/${id}`),
  saveWorkflowCopywritingById: (id: number) =>
    requests.put(`${WORKFLOW_URL}/copywriting/save/${id}`),
  getNextTranslationWorkflow: (workflowId: number) =>
    requests.get(`${WORKFLOW_URL}/translation/next/${workflowId}`),
  saveWorkflowTranslationById: (id: number) =>
    requests.put(`${WORKFLOW_URL}/translation/save/${id}`),
  workflowComplete: (id: number) =>
    requests.put(`${WORKFLOW_URL}/export/save/${id}`),

  getTranslationProducts: (
    params: any,
    id: number
  ): Promise<BaseModel<IWorkflow[]>> =>
    requests.get(`${WORKFLOW_URL}/translation/list/${id}`, params),

  export: (view: string, format: string, id: number) =>
    requests.put(
      `${WORKFLOW_URL}/export/export-workflow-product/${format}/${view}/${id}`
    ),

  assignTranslator: (id: number, payload: ICopywriterData) =>
    requests.put(`${WORKFLOW_URL}/translation/assign-multiple/${id}`, payload),
  assignTranslatorBaseData: (id: number, payload: ICopywriterData) =>
    requests.put(`${WORKFLOW_URL}/base-data/assign-multiple/${id}`, payload),
  assignTranslatorAttributes: (id: number, payload: ICopywriterData) =>
    requests.put(`${WORKFLOW_URL}/attributes/assign-multiple/${id}`, payload),
  getTranslationProductById: (workflowId: number, id: number) =>
    requests.get(
      `${WORKFLOW_URL}/translation/product-data/${workflowId}/${id}`
    ),
  updateTranslationProductById: (
    params: FormData,
    id: number,
    workflowId: number
  ) =>
    requests.put(
      `${WORKFLOW_URL}/translation/product-data/${workflowId}/${id}`,
      params
    ),
  changeFinishedStatusTranslation: (id: number, payload: any, status: string) =>
    requests.put(
      `${WORKFLOW_URL}/${status}/product-status-change/${id}`,
      payload
    ),
  changeFinishedStatusCopyWrite: (id: number, payload: any) =>
    requests.put(
      `${WORKFLOW_URL}/copywriting/product-status-change/${id}`,
      payload
    ),
  changeFinishedStatusBase: (id: number, payload: any) =>
    requests.put(`${WORKFLOW_URL}/product-status-change/${id}`, payload),
  changeFinishedStatusAtt: (id: number, payload: any) =>
    requests.put(
      `${WORKFLOW_URL}/attributes/product-status-change/${id}`,
      payload
    ),
  workflowTranslateAll: (id: number, params: any) =>
    requests.put(
      `${WORKFLOW_URL}/translation/translate-all-products/${id}`,
      params
    ),
  createImportMapping: (params: any, workflowId: number) =>
    requests.post(`${IMPORT_MAPPING_URL}create/${workflowId}`, params),
  newUpdateImportMapping: (params: any, workflowId: number) =>
    requests.post(`${IMPORT_MAPPING_URL}update/${workflowId}`, params),
  newMapImportMapping: (params: any, workflowId: number) =>
    requests.post(`${IMPORT_MAPPING_URL}enrich/${workflowId}`, params),
  getAllImportMapping: (workflowId: number) =>
    requests.get(`${IMPORT_MAPPING_URL}list/${workflowId}`),
  updateImportMapping: (params: any, profileId: number) =>
    requests.put(`${IMPORT_MAPPING_URL}update/${profileId}`, params),
  getProfile: (profileId: number) =>
    requests.get(`${IMPORT_MAPPING_URL}detail/${profileId}`),
  previewImportMapping: (params: any) =>
    requests.post(`${IMPORT_MAPPING_URL}preview`, params),
  importMapping: (params: any, workflowId: number) =>
    requests.post(
      `${IMPORT_MAPPING_URL}import-to-workflow/${workflowId}`,
      params
    ),
  exportProducts: (body: any) =>
    requests.put(`${WORKFLOW_URL}/products-add`, body!),
  generateAiVariations: (id: number) =>
    requests.put(`${WORKFLOW_URL}/generate-variations/${id}`),
  lockWorkflow: (id: number, params: any) =>
    requests.put(`${WORKFLOW_URL}/lock/lock/${id}`, params),
  unlockWorkflow: (id: number, params: any) =>
    requests.put(`${WORKFLOW_URL}/lock/unlock/${id}`, params),
};
