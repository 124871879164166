// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DefaultEditor } from "react-simple-wysiwyg";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { IAllStates } from "../../../../store/rootReducer";
import i18n from "../../../../utils/translations/i18next";
import { BlockService } from "../../../block/service";
import { ProductsService } from "../../service";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../../shared/functions/Functions";
import { getCorrectFormality } from "../../../../shared/functions/Functions";
// import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// eslint-disable-next-line @typescript-eslint/no-redeclare
import Editor from "ckeditor5-custom-build/build/ckeditor";
import _debounce from "lodash/debounce";
import "../../../../ckeditor.css";

interface ICounts {
  [nameField: string]: {
    words: number;
    characters: number;
  };
}
interface IEditorCounter {
  [key: string]: ICounts;
}
const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      "|",
      "glossary-scan",
      // "glossary-ignore",
      "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};
interface VariationUpdateFormProps {
  setCurrentVariation: any;
  currentVariation: any;
  showVariationModal: boolean;
  handleClose: any;
  variationId?: number;
  certainBlocks?: any;
  isView?: boolean;
  setTriggerUpdate?: any;
}

const VariationUpdateForm = ({
  currentVariation,
  showVariationModal,
  handleClose,
  variationId,
  setCurrentVariation,
  certainBlocks,
  isView,
  setTriggerUpdate,
}: VariationUpdateFormProps) => {
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [field, setField] = useState("");
  setTriggerUpdate(false);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  let result = "";
  const { t } = useTranslation();
  const currentLanguage = i18n?.language;
  const [activeTab, setActiveTab] = useState(currentLanguage);
  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [formality, setFormality] = useState(null);
  const [allBlocks, setAllBlocks] = useState([] as any);
  const [activeBlocks, setActiveBlocks] = useState([] as any);
  const [arrVariationBlocks, setArrVariationBlocks] = useState([]);
  const [wordCount, setWordCount] = useState<IEditorCounter>();
  const updateWordsCound = _debounce(
    ({ wordsCount, charactersCount, language }, nameField) => {
      setWordCount((prev) => ({
        ...prev,
        [language]: {
          ...prev?.[language],
          [nameField]: {
            ...prev?.[language]?.[nameField],
            words: wordsCount,
            characters: charactersCount,
          },
        },
      }));
    },
    500
  );
  const changeVariationTranslationHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    const { value, name } = event.target;
    setCurrentVariation((prev: any) => ({
      ...prev,
      [language]: {
        ...prev[language],
        [name]: value,
      },
    }));
  };
  const changeHandlerEditor = (
    value: string,
    language: string,
    eventName: string
  ) => {
    setCurrentVariation((prev: any) => ({
      ...prev,
      [language]: {
        ...prev[language],
        [eventName]: value,
      },
    }));
  };
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }

  const changeBlockTranslationHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string,
    blockId: number
  ) => {
    const { value } = event.target;
    setCurrentVariation((prevState: any) => ({
      ...prevState,
      [language]: {
        ...prevState[language],
        blocks: {
          ...prevState[language]?.blocks,
          [blockId]: value,
        },
      },
    }));
  };
  const changeHandlerEditorBlock = (
    value: string,
    language: string,
    //  eventName: string,
    blockId: number
  ) => {
    setCurrentVariation((prevState: any) => ({
      ...prevState,
      [language]: {
        ...prevState[language],
        blocks: {
          ...prevState[language]?.blocks,
          [blockId]: value,
        },
      },
    }));
  };

  const handleUpdateVariation = (event: React.FormEvent, language: string) => {
    event.preventDefault();
    ProductsService.updateVariation(
      {
        lang: language,
        title: currentVariation?.[language]?.title ?? null,
        description: currentVariation?.[language]?.description,
        subtitle: currentVariation?.[language]?.subtitle,
        bullets: currentVariation?.[language]?.bullets,
        introText: currentVariation?.[language]?.introText,
        blocks: currentVariation?.[language]?.blocks,
      } as any,
      Number(variationId)
    )
      .then((response) => {
        SuccessToast(
          `${t("toasts.successfully_updated")} ${
            response?.translation?.["en"]?.title
          }`
        );
        setTriggerUpdate((prev: boolean) => !prev);
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    if (variationId) {
      ProductsService.getVariation(variationId)
        .then((response) => {
          setArrVariationBlocks(response?.blocks);
          setCurrentVariation(response?.translation);
        })
        .catch((error) => ErrorToast(error));
    }
  }, []);

  useEffect(() => {
    BlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setAllBlocks(data as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {}, []);

  useEffect(() => {
    if (!!arrVariationBlocks.length && !!allBlocks.length) {
      const currentlyBlocksForProduct = getCertainBlocks(
        arrVariationBlocks as any,
        allBlocks
      );
      setActiveBlocks(currentlyBlocksForProduct);
    }
  }, [JSON.stringify(arrVariationBlocks), JSON.stringify(allBlocks)]);

  const getCertainBlocks = (block: any, listOfAllBlocks: any) => {
    const data: any[] = [];
    listOfAllBlocks?.forEach((item: any) => {
      if (block.some((a: number) => a === item.id)) {
        data.push(item);
      }
    });
    return data;
  };
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>,
    language: string,
    fieldName: string
  ) {
    setSourceLanguage(selectedSingle?.value as string);
    setTargetLanguage(language as string);
    setField(fieldName as string);
  }
  const handleTranslateVariation = () => {
    ProductsService.postTranslation({
      text: currentVariation?.[sourceLanguage]?.[field],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response: any) => {
        if (
          currentVariation?.[targetLanguage]?.[field] === null ||
          currentVariation?.[targetLanguage]?.[field] === ""
        ) {
          result = response;
        } else {
          result = currentVariation?.[targetLanguage]?.[field].concat(
            " ",
            response
          );
        }
        setCurrentVariation((prev: any) => ({
          ...prev,
          [targetLanguage]: {
            ...prev?.[targetLanguage],
            [field]: result,
          },
        }));
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const handleTranslate = () => {
    ProductsService.postTranslation({
      text: currentVariation?.[sourceLanguage]?.blocks?.[field],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response) => {
        if (
          currentVariation?.[targetLanguage]?.blocks?.[field] === null ||
          currentVariation?.[targetLanguage]?.blocks?.[field] === ""
        ) {
          result = response;
        } else {
          result = currentVariation?.[targetLanguage]?.blocks?.[field].concat(
            " ",
            response
          );
        }
        setCurrentVariation((prev: any) => ({
          ...prev,
          [targetLanguage]: {
            ...prev?.[targetLanguage],
            blocks: {
              ...prev?.[targetLanguage]?.blocks,
              [field]: result,
            },
          },
        }));
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div>
      <Modal show={showVariationModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          {!isView ? (
            <Modal.Title>{t("products.variation.updateVariation")}</Modal.Title>
          ) : (
            <Modal.Title>{t("products.variation.viewVariation")}</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <ul
            className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
            role="tablist"
          >
            {translationLanguageEnum?.map((language) => {
              return (
                <li
                  className="nav-item"
                  key={language}
                  onClick={() => changeActiveTab(language)}
                >
                  <a
                    className={`nav-link ${
                      activeTab === language ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href={`#${language}`}
                    role="tab"
                    aria-selected={language === currentLanguage}
                  >
                    {t(`products.content_${language}`)}
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="tab-content">
            {translationLanguageEnum?.map((language) => {
              return (
                <>
                  <div
                    className={`tab-pane  ${
                      activeTab === language ? "active" : ""
                    }`}
                    id={language}
                    role="tabpanel"
                  >
                    <div className="row gy-3">
                      <div className="col-md-6">
                        <label htmlFor="title" className="required-field">
                          {t("products.title")}
                        </label>
                        <input
                          disabled={isView}
                          type="text"
                          className="form-control"
                          name="title"
                          onChange={(e) =>
                            changeVariationTranslationHandler(e, language)
                          }
                          value={currentVariation?.[language]?.title ?? null}
                          placeholder={t("products.enter_title")}
                        />
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="subtitle">
                          {t("products.subtitle")}
                        </label>
                        <input
                          disabled={isView}
                          type="text"
                          className="form-control"
                          name="subtitle"
                          onChange={(e) =>
                            changeVariationTranslationHandler(e, language)
                          }
                          value={currentVariation?.[language]?.subtitle ?? ""}
                          placeholder={t("products.enter_subtitle")}
                        />
                      </div> */}
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center my-1">
                          <label className="m-0" htmlFor="description">
                            {t("products.description")}
                          </label>
                          {!isView && (
                            <div className="d-flex justify-content-end align-items-center my-1">
                              <>
                                <Select
                                  className="custom_zindex react-select-field me-2"
                                  name="content"
                                  theme={themeSelect}
                                  placeholder={`${t(
                                    "formality.select_formality"
                                  )}`}
                                  options={deeplFormality?.map((formality) => ({
                                    value: formality,
                                    label: getCorrectFormality(formality, t),
                                  }))}
                                  // key={state?.id}
                                  onChange={(e) => {
                                    handleSelectSingleFormality(e as any);
                                  }}
                                />
                                <div className="">
                                  <Select
                                    className="custom_zindex react-select-field"
                                    theme={themeSelect}
                                    placeholder="Translate from"
                                    isClearable={true}
                                    options={translationLanguageEnum
                                      .filter((lang) => lang !== language)
                                      ?.map((lang) => ({
                                        value: lang,
                                        label: getCorrectLanguage(lang, t),
                                      }))}
                                    //   key={state?.id}
                                    onChange={(e) => {
                                      handleSelectSingle(
                                        e as any,
                                        language as string,
                                        "description"
                                      );
                                    }}
                                  />
                                </div>
                                <button
                                  className="btn btn-secondary ms-3"
                                  onClick={handleTranslateVariation}
                                >
                                  {t("global.translate")}
                                </button>
                              </>
                            </div>
                          )}
                        </div>
                        <CKEditor
                          config={editorConfigurationCustom}
                          disabled={isView}
                          editor={Editor}
                          data={currentVariation?.[language]?.description ?? ""}
                          onReady={(editor) => {
                            editor.ui.view.element.setAttribute(
                              "id",
                              String(Math.random().toString(16).slice(2))
                            );
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            setWordCount((prev) => ({
                              ...prev,
                              [language]: {
                                ...prev?.[language],
                                description: {
                                  ...prev?.[language]?.["description"],
                                  words: wordsCount,
                                  characters: charactersCount,
                                },
                              },
                            }));
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            changeHandlerEditor(
                              data,
                              language as string,
                              "description"
                            );
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            updateWordsCound(
                              {
                                wordsCount,
                                charactersCount,
                                language,
                              },
                              "description"
                            );
                          }}
                        />
                        <div className="d-flex justify-content-start text-align-center mt-3">
                          <div className="ck ck-word-count">
                            <div className="ck-word-count__words">
                              Words:{" "}
                              {wordCount?.[language]?.["description"]?.words}
                            </div>
                            <div className="ck-word-count__characters">
                              Characters:{" "}
                              {
                                wordCount?.[language]?.["description"]
                                  ?.characters
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center my-1">
                          <label className="m-0" htmlFor="introText">
                            {t("products.introText")}
                          </label>
                          {!isView && (
                            <div className="d-flex justify-content-end align-items-center my-1">
                              <>
                                <Select
                                  className="custom_zindex react-select-field me-2"
                                  name="content"
                                  theme={themeSelect}
                                  placeholder={`${t(
                                    "formality.select_formality"
                                  )}`}
                                  options={deeplFormality?.map((formality) => ({
                                    value: formality,
                                    label: getCorrectFormality(formality, t),
                                  }))}
                                  // key={state?.id}
                                  onChange={(e) => {
                                    handleSelectSingleFormality(e as any);
                                  }}
                                />
                                <div className="">
                                  <Select
                                    className="custom_zindex react-select-field"
                                    theme={themeSelect}
                                    placeholder="Translate from"
                                    isClearable={true}
                                    options={translationLanguageEnum
                                      .filter((lang) => lang !== language)
                                      ?.map((lang) => ({
                                        value: lang,
                                        label: getCorrectLanguage(lang, t),
                                      }))}
                                    //   key={state?.id}
                                    onChange={(e) => {
                                      handleSelectSingle(
                                        e as any,
                                        language as string,
                                        "introText"
                                      );
                                    }}
                                  />
                                </div>
                                <button
                                  className="btn btn-secondary ms-3"
                                  onClick={handleTranslateVariation}
                                >
                                  {t("global.translate")}
                                </button>
                              </>
                            </div>
                          )}
                        </div>
                        <CKEditor
                          // config={{ removePlugins: ["Title"] }}
                          config={editorConfigurationCustom}
                          disabled={isView}
                          editor={Editor}
                          data={currentVariation?.[language]?.introText ?? ""}
                          onReady={(editor) => {
                            editor.ui.view.element.setAttribute(
                              "id",
                              String(Math.random().toString(16).slice(2))
                            );
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            setWordCount((prev) => ({
                              ...prev,
                              [language]: {
                                ...prev?.[language],
                                introText: {
                                  ...prev?.[language]?.["introText"],
                                  words: wordsCount,
                                  characters: charactersCount,
                                },
                              },
                            }));
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            changeHandlerEditor(
                              data,
                              language as string,
                              "introText"
                            );
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            updateWordsCound(
                              {
                                wordsCount,
                                charactersCount,
                                language,
                              },
                              "introText"
                            );
                          }}
                        />
                        <div className="d-flex justify-content-start text-align-center mt-3">
                          <div className="ck ck-word-count">
                            <div className="ck-word-count__words">
                              Words:{" "}
                              {wordCount?.[language]?.["introText"]?.words}
                            </div>
                            <div className="ck-word-count__characters">
                              Characters:{" "}
                              {wordCount?.[language]?.["introText"]?.characters}
                            </div>
                          </div>
                        </div>
                        {/* <Editor
                          disabled={isView}
                          init={{
                            placeholder: `${t("products.enter_introText")}`,
                          }}
                          value={currentVariation?.[language]?.introText ?? ""}
                          apiKey={process.env.REACT_APP_TINY_API}
                          onEditorChange={(value) =>
                            changeHandlerEditor(
                              value,
                              language as string,
                              "introText"
                            )
                          }
                        /> */}
                        {/* <DefaultEditor
                          disabled={isView}
                          value={currentVariation?.[language]?.introText ?? ""}
                          name="introText"
                          placeholder={t("products.enter_introText")}
                          onChange={(e) =>
                            changeVariationTranslationHandler(
                              e as any,
                              language
                            )
                          }
                        /> */}
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center my-1">
                          <label className="m-0" htmlFor="bullets">
                            {t("products.bullets")}
                          </label>
                          {!isView && (
                            <div className="d-flex justify-content-end align-items-center my-1">
                              <>
                                <Select
                                  className="custom_zindex react-select-field me-2"
                                  name="content"
                                  theme={themeSelect}
                                  placeholder={`${t(
                                    "formality.select_formality"
                                  )}`}
                                  options={deeplFormality?.map((formality) => ({
                                    value: formality,
                                    label: getCorrectFormality(formality, t),
                                  }))}
                                  // key={state?.id}
                                  onChange={(e) => {
                                    handleSelectSingleFormality(e as any);
                                  }}
                                />
                                <div className="">
                                  <Select
                                    className="custom_zindex react-select-field"
                                    theme={themeSelect}
                                    placeholder="Translate from"
                                    isClearable={true}
                                    options={translationLanguageEnum
                                      .filter((lang) => lang !== language)
                                      ?.map((lang) => ({
                                        value: lang,
                                        label: getCorrectLanguage(lang, t),
                                      }))}
                                    //   key={state?.id}
                                    onChange={(e) => {
                                      handleSelectSingle(
                                        e as any,
                                        language as string,
                                        "bullets"
                                      );
                                    }}
                                  />
                                </div>
                                <button
                                  className="btn btn-secondary ms-3"
                                  onClick={handleTranslateVariation}
                                >
                                  {t("global.translate")}
                                </button>
                              </>
                            </div>
                          )}
                        </div>
                        <CKEditor
                          // config={{ removePlugins: ["Title"] }}
                          config={editorConfigurationCustom}
                          disabled={isView}
                          editor={Editor}
                          data={currentVariation?.[language]?.bullets ?? ""}
                          onReady={(editor) => {
                            editor.ui.view.element.setAttribute(
                              "id",
                              String(Math.random().toString(16).slice(2))
                            );
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            setWordCount((prev) => ({
                              ...prev,
                              [language]: {
                                ...prev?.[language],
                                bullets: {
                                  ...prev?.[language]?.["bullets"],
                                  words: wordsCount,
                                  characters: charactersCount,
                                },
                              },
                            }));
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            changeHandlerEditor(
                              data,
                              language as string,
                              "bullets"
                            );
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            updateWordsCound(
                              {
                                wordsCount,
                                charactersCount,
                                language,
                              },
                              "bullets"
                            );
                          }}
                        />
                        <div className="d-flex justify-content-start text-align-center mt-3">
                          <div className="ck ck-word-count">
                            <div className="ck-word-count__words">
                              {t("global.words")}:{" "}
                              {wordCount?.[language]?.["bullets"]?.words}
                            </div>
                            <div className="ck-word-count__characters">
                              Characters:{" "}
                              {wordCount?.[language]?.["bullets"]?.characters}
                            </div>
                          </div>
                        </div>
                        {/* <Editor
                          disabled={isView}
                          init={{
                            placeholder: `${t("products.enter_bullets")}`,
                          }}
                          value={currentVariation?.[language]?.bullets ?? ""}
                          apiKey={process.env.REACT_APP_TINY_API}
                          onEditorChange={(value) =>
                            changeHandlerEditor(
                              value,
                              language as string,
                              "bullets"
                            )
                          }
                        /> */}
                        {/* <DefaultEditor
                          disabled={isView}
                          value={currentVariation?.[language]?.bullets ?? ""}
                          name="bullets"
                          placeholder={t("products.enter_bullets")}
                          onChange={(e) =>
                            changeVariationTranslationHandler(
                              e as any,
                              language
                            )
                          }
                        /> */}
                      </div>
                      {!!activeBlocks?.length &&
                        activeBlocks?.map((block: any) => (
                          <div className="col-md-12">
                            <div className="d-flex justify-content-between align-items-center my-1">
                              <label htmlFor="value">{block?.name}</label>
                              {!isView && (
                                <div className="d-flex justify-content-end align-items-center my-1">
                                  <>
                                    <Select
                                      className="custom_zindex react-select-field me-2"
                                      name="content"
                                      theme={themeSelect}
                                      placeholder={`${t(
                                        "formality.select_formality"
                                      )}`}
                                      options={deeplFormality?.map(
                                        (formality) => ({
                                          value: formality,
                                          label: getCorrectFormality(
                                            formality,
                                            t
                                          ),
                                        })
                                      )}
                                      // key={state?.id}
                                      onChange={(e) => {
                                        handleSelectSingleFormality(e as any);
                                      }}
                                    />
                                    <div className="">
                                      <Select
                                        className="custom_zindex react-select-field"
                                        name={block.name}
                                        theme={themeSelect}
                                        placeholder="Translate from"
                                        options={translationLanguageEnum
                                          .filter((lang) => lang !== language)
                                          ?.map((lang) => ({
                                            value: lang,
                                            label: getCorrectLanguage(lang, t),
                                          }))}
                                        // key={state?.id}
                                        onChange={(e) => {
                                          handleSelectSingle(
                                            e as any,
                                            language as string,
                                            block?.id
                                          );
                                        }}
                                      />
                                    </div>
                                    <button
                                      className="btn btn-secondary ms-3"
                                      onClick={handleTranslate}
                                    >
                                      {t("global.translate")}
                                    </button>
                                  </>
                                </div>
                              )}
                            </div>
                            <CKEditor
                              // config={{ removePlugins: ["Title"] }}
                              config={editorConfigurationCustom}
                              editor={Editor}
                              disabled={isView}
                              data={
                                currentVariation?.[language]?.blocks?.[
                                  block?.id
                                ] as string
                              }
                              onReady={(editor) => {
                                editor.ui.view.element.setAttribute(
                                  "id",
                                  String(Math.random().toString(16).slice(2))
                                );
                                const wordsCount =
                                  editor.plugins.get("WordCount").words;
                                const charactersCount =
                                  editor.plugins.get("WordCount").characters;
                                setWordCount((prev) => ({
                                  ...prev,
                                  [language]: {
                                    ...prev?.[language],
                                    [block.name]: {
                                      ...prev?.[language]?.[block.name],
                                      words: wordsCount,
                                      characters: charactersCount,
                                    },
                                  },
                                }));
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                changeHandlerEditorBlock(
                                  data,
                                  language as string,
                                  // "block",
                                  block?.id as number
                                );
                                const wordsCount =
                                  editor.plugins.get("WordCount").words;
                                const charactersCount =
                                  editor.plugins.get("WordCount").characters;
                                updateWordsCound(
                                  {
                                    wordsCount,
                                    charactersCount,
                                    language,
                                  },
                                  block.name
                                );
                              }}
                            />
                            <div className="d-flex justify-content-start text-align-center mt-3">
                              <div className="ck ck-word-count">
                                <div className="ck-word-count__words">
                                  Words:{" "}
                                  {wordCount?.[language]?.[block.name]?.words}
                                </div>
                                <div className="ck-word-count__characters">
                                  Characters:{" "}
                                  {
                                    wordCount?.[language]?.[block.name]
                                      ?.characters
                                  }
                                </div>
                              </div>
                            </div>
                            {/* <Editor
                              disabled={isView}
                              init={{
                                placeholder: `${t("products.enter_block")}`,
                              }}
                              value={
                                currentVariation?.[language]?.blocks?.[
                                  block?.id
                                ] as string
                              }
                              apiKey={process.env.REACT_APP_TINY_API}
                              onEditorChange={(value) =>
                                changeHandlerEditorBlock(
                                  value,
                                  language as string,
                                  // "block",
                                  block?.id as number
                                )
                              }
                            /> */}
                            {/* <DefaultEditor
                              disabled={isView}
                              value={
                                currentVariation?.[language]?.blocks?.[
                                  block?.id
                                ] as string
                              }
                              name="value"
                              placeholder={t("products.enter_block")}
                              id={block?.id}
                              onChange={(e) =>
                                changeBlockTranslationHandler(
                                  e as any,
                                  language,
                                  block?.id
                                )
                              }
                            /> */}
                          </div>
                        ))}
                    </div>
                    {!isView ? (
                      <Modal.Footer>
                        <Button variant="info" onClick={handleClose}>
                          {t("global.cancel")}
                        </Button>
                        <Button
                          variant="primary"
                          onClick={(e) => handleUpdateVariation(e, language)}
                        >
                          {t("category.save")}
                        </Button>
                      </Modal.Footer>
                    ) : (
                      <Modal.Footer>
                        <Button variant="info" onClick={handleClose}>
                          {t("global.cancel")}
                        </Button>
                      </Modal.Footer>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default VariationUpdateForm;
