import { combineReducers } from "redux";
import { IWorkflow } from "../app/workflow/dto/IWorkflow";
import enumsSlice from "./enums/enumsRedux";
import { IEnums } from "../model/shared/IEnums";
import authSlice from "./auth/authRedux";
import { IProfile } from "../app/profile/dto/IProfile";
import categorySlice from "./category/categoryRedux";
import { ICategoryDTO } from "../app/category/values/dto/ICategory";
import parentCategoryIdSlice from "./category/parentCategoryIdRedux";
import firstViewSlice from "./category/firstView";
import contentFirstViewSlice from "./category/contentFirstView";
import { appVersionSlice } from "../utils/general/appVersionSlice";
import workflowSlice from "./workflow/workflowSlice";
import expOrAttPaginationSlice from "./category/expOrAttPagination";
import secondViewPaginationSlice from "./category/secondViewPagination";
import lightOrDarkModeSlice from "./category/lightOrDarkMode";
import contentWorkflowSlice from "./contentWorkflow/contentWorkflowSlice";
import workflowProductIdSlice from "./workflow/workflowLangSlice";
import workflowLangSlice from "./workflow/workflowLangSlice";
import step3PaginationSlice from "./workflow/step3PaginationSlice";
import step4PaginationSlice from "./workflow/step4PaginationSlice";
import step5PaginationSlice from "./workflow/step5PaginationSlice";
import step6PaginationSlice from "./workflow/step6PaginationSlice";
import changeLocationSlice from "./category/changeLocation";
import clientIdSlice from "./clientMapping/clientId";
import open423ErrModalSlice from "../utils/general/open423ErrModalSlice";

export interface IAllStates {
  enums: IEnums;
  auth: IProfile;
  category: ICategoryDTO[];
  parentId: Number;
  firstView: boolean;
  changeLocation: boolean;
  contentFirstView: boolean;
  appVersion: { openRefreshModal: boolean };
  workflow: IWorkflow;
  open423ErrModal: boolean;
  expOrAttPagination: any;
  secondViewPagination: any;
  lightOrDarkMode: string;
  contentWorkflow: any;
  workflowLang: string;
  step3Pagination: any;
  step4Pagination: any;
  step5Pagination: any;
  step6Pagination: any;
  clientId: Number;
}

export const rootReducer = combineReducers({
  enums: enumsSlice.reducer,
  auth: authSlice.reducer,
  category: categorySlice.reducer,
  parentId: parentCategoryIdSlice.reducer,
  firstView: firstViewSlice.reducer,
  changeLocation: changeLocationSlice.reducer,
  contentFirstView: contentFirstViewSlice.reducer,
  appVersion: appVersionSlice.reducer,
  workflow: workflowSlice.reducer,
  open423ErrModal: open423ErrModalSlice.reducer,
  expOrAttPagination: expOrAttPaginationSlice.reducer,
  secondViewPagination: secondViewPaginationSlice.reducer,
  lightOrDarkMode: lightOrDarkModeSlice.reducer,
  contentWorkflow: contentWorkflowSlice.reducer,
  workflowLang: workflowLangSlice.reducer,
  step3Pagination: step3PaginationSlice.reducer,
  step4Pagination: step4PaginationSlice.reducer,
  step5Pagination: step5PaginationSlice.reducer,
  step6Pagination: step6PaginationSlice.reducer,
  clientId: clientIdSlice.reducer,
});
