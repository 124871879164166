import { ActualFileObject } from "filepond";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { Pagination } from "../../../../shared/paginations/Paginations";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { IExport } from "../../../exports/dto/IExports";
import { ExportService } from "../../../exports/service";
import { IProducts, IProductsList, IProductValue } from "../../dto/IProducts";
import { ProductsService } from "../../service";
import { ProductsAttributesActions } from "./ProductsAttributesActions";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import ImageContainer from "../../../../shared/gallery/ImageContainer";
import changeLocationSlice from "../../../../store/category/changeLocation";
import { Spinner, Table, Offcanvas } from "react-bootstrap";
import { GoogleSearchPromptService } from "../../../googleSearchPrompt/service";
import { OffcanvasAttributeLeftSide } from "./OffcanvasAttributeLeftSide";

interface Props {
  isWorkflowUpdate?: boolean;
  selectedProductId?: number;
  setSelectedProductId?: React.Dispatch<SetStateAction<number>> | undefined;
}

export const ProductsAttributesUpdate = ({
  isWorkflowUpdate = false,
  selectedProductId,
  setSelectedProductId,
}: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const { t } = useTranslation();
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [triggerOffcanvas, setTriggerOffcanvas] = useState(false);
  const [variationPagination, setVariationPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const workflowData = useSelector((state: IAllStates) => state.workflow);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const tab = "BASE_DATA";
  const { state: close }: any = useLocation();
  const [totalPages, setTotalPages] = useState(0);
  const [googleSearchList, setGoogleSearchList] = useState<any[]>([]);
  const navigate = useNavigate();
  const [googleSearchPrompt, setGoogleSearchPrompt] = useState<number>(0);
  const [currentProduct, setCurrentProduct] = useState<IProductsList>();
  const [exportsList, setExportsList] = useState<IExport[]>([]);
  const [page, setPage] = useState(1);
  const secondViewPagination = useSelector(
    (state: IAllStates) => state.secondViewPagination as any
  );
  const { state: pagination }: any = useLocation();
  const handlePages = (updatePage: number) => {
    setVariationPagination({ ...variationPagination, page: updatePage });
    setPage(updatePage);
  };
  const [exportData, setExportData] = useState<{
    clientExport: number;
  }>();
  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>,
    inputKey: string
  ) => {
    dispatch(changeLocationSlice.actions.setChangeLocation(true));
    setCurrentProduct((prev: any) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
  };
  const submitGoogleSearch = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    GoogleSearchPromptService.enrichProduct({
      productId: currentProduct?.id,
      promptId: googleSearchPrompt,
      titleLanguage: workflowData?.sourceLang ?? "de",
    })
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_created")}`);
        setCurrentProduct((prev: any) => ({
          ...prev,
          enrichmentData: response?.enrichmentData,
        }));
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setIsLoading(false));
  };
  const getAiInformation = (e: any) => {
    e.preventDefault();
    if ((currentProduct as any)?.enrichmentData?.formBuilderAttributesData) {
      return;
    } else {
      setIsLoadingTable(false);
      // !(currentProduct as any)?.enrichmentData?.formBuilderAttributesData &&
      ProductsService.enrichAttributes(Number(currentProduct?.id))
        .then((response) => {
          setCurrentProduct((prev: any) => ({
            ...prev,
            enrichmentData: response,
          }));
          setTriggerOffcanvas((prev: boolean) => !prev);
        })
        .catch((error) => ErrorToast(error))
        .finally(() => setIsLoadingTable((prev: boolean) => !prev));
    }
  };
  const refreshAiInformation = (e: any) => {
    e.preventDefault();
    setIsLoadingTable(false);
    // !(currentProduct as any)?.enrichmentData?.formBuilderAttributesData &&
    ProductsService.enrichRefreshAttributes(Number(currentProduct?.id))
      .then((response) => {
        setCurrentProduct((prev: any) => ({
          ...prev,
          enrichmentData: response,
        }));
        setTriggerOffcanvas((prev: boolean) => !prev);
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setIsLoadingTable((prev: boolean) => !prev));
  };
  useEffect(() => {
    GoogleSearchPromptService.getAllPrompts({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setGoogleSearchList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const checkHref = () => {
    if (
      currentProduct?.sourceUrl === null ||
      currentProduct?.sourceUrl === "" ||
      currentProduct?.sourceUrl === undefined
    ) {
      return "/403";
    } else if (
      currentProduct?.sourceUrl?.includes("https://") ||
      currentProduct?.sourceUrl?.includes("http://")
    ) {
      return currentProduct?.sourceUrl;
    } else {
      return `https://${currentProduct?.sourceUrl}`;
    }
  };
  const changeValuesHandler = (values: any, attributeId: number) => {
    let tempArr: IProductValue[] = currentProduct?.values ?? [];
    const indexToReplace = tempArr?.findIndex(
      (attribute) => attribute.attribute === attributeId
    );
    const addId = values?.find((item: { isAdd: boolean }) => item.isAdd)
      ?.value as number;

    if (indexToReplace === -1) {
      tempArr?.push({
        attribute: attributeId,
        value: values
          ?.map((value: { value: number }) => value.value)
          .filter((item: number) => item !== addId),
      });
    } else {
      tempArr[indexToReplace] = {
        ...tempArr[indexToReplace],
        value: values
          ?.map((value: { value: number }) => value.value)
          .filter((item: number) => item !== addId),
      };
    }
    dispatch(changeLocationSlice.actions.setChangeLocation(true));
    setCurrentProduct((prev: any) => ({ ...prev, values: tempArr }));
  };
  function closeOffcanvas() {
    const offcanvas = document.querySelector(".offcanvas");
    const backdrop = document.querySelector(".offcanvas-backdrop");
    offcanvas?.classList.remove("show");
    backdrop?.classList.remove("show");
  }
  const selectExportHandler = (e: any) => {
    setExportData({ clientExport: +e.value });
  };
  const selectGoogleSearchHandler = (e: any) => {
    setGoogleSearchPrompt(+e.value);
  };
  const exportClickHandler = () => {
    ExportService.exportProduct(exportData, +id!)
      .then((res) => {
        SuccessToast(`${t("toasts.successfully_exported")}`);
      })
      .catch((err) => console.log(err));
  };
  const handleClose = () => {
    if (isWorkflowUpdate) {
      navigate(`/app/workflow/step4/${id}`);
    } else {
      navigate("/app/products/attributes", {
        state:
          (location.state as any)?.pagination ??
          secondViewPagination?.pagination,
      });
    }
    // navigate("/app/pending-products/imported", { state: { pagination: pagination?.pagination ?? {} } });
  };
  // const OffcanvasAttributeCallback = useCallback(() => {
  //   return (
  //     <OffcanvasAttributeLeftSide
  //       currentProduct={currentProduct}
  //       isLoadingTable={isLoadingTable}
  //     />
  //   );
  // }, [triggerOffcanvas, isLoadingTable]);
  useEffect(() => {
    ExportService.getAllExports({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setExportsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const getProductId = () => {
    id &&
      tab &&
      ProductsService.getProductById(
        isWorkflowUpdate ? Number(selectedProductId) : +id,
        tab
      )
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => {
          ErrorToast(error);
          //novo
          navigate(-1);
        });
  };
  useEffect(() => {
    getProductId();
  }, [id, selectedProductId, isWorkflowUpdate]);
  const navContent = () => {
    if (close?.close === "export") {
      navigate(`/app/products/edit-content/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/edit-content/${id}`, { state: pagination });
    }
  };
  const navBaseData = () => {
    if (close?.close === "export") {
      navigate(`/app/products/base-data/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/base-data/${id}`, { state: pagination });
    }
  };
  const backHandle = () => {
    if (close?.close === "export") {
      navigate(`/app/export/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
      //  navigate(-1)
    } else if (close?.close === "attributes") {
      navigate(`/admin/attributes/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
    } else if (handleClose) {
      handleClose();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      {isWorkflowUpdate && (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <button
              onClick={() => backHandle()}
              className="btn btn-info d-flex align-items-start justify-content-start mb-3"
            >
              <i className="ri-arrow-left-line me-1"></i>
              {t("products.back")}
            </button>
            <a
              rel="noreferrer"
              target="_blank"
              href={checkHref()}
              // href={`${currentProduct?.sourceUrl === null ? "/403" : `${currentProduct?.sourceUrl}`} `}
            >
              <button className="btn btn-primary">
                {t("products.source_url")}
              </button>
            </a>
          </div>

          <div className="d-flex  align-items-center mb-3">
            <div className="d-flex gap-3 align-items-center">
              <ImageContainer img={currentProduct?.productPictures ?? []} />
              <div>
                <h2>{currentProduct?.title}</h2>
                <p style={{ marginBottom: "0.5rem" }}>
                  {currentProduct?.eanCode}
                </p>
                <p style={{ marginBottom: "0.5rem" }}>
                  {currentProduct?.brand?.name ?? "-"}
                </p>
                <div className="d-flex">
                  {accessRights?.includes(
                    "data_enrichment_workflow_single_product"
                  ) && (
                    <>
                      <Select
                        className="react-select-field w-250"
                        placeholder={"Google search prompt"}
                        onChange={selectGoogleSearchHandler}
                        // isClearable={true}
                        options={googleSearchList.map((exportItem) => ({
                          label: exportItem.title,
                          value: exportItem.id,
                        }))}
                      />
                      <button
                        style={{ minWidth: "141px" }}
                        className="btn btn-primary ms-1 me-2"
                        disabled={googleSearchPrompt === 0}
                        onClick={(e: any) => submitGoogleSearch(e)}
                      >
                        {isLoading ? (
                          <Spinner animation="border" size="sm" role="status" />
                        ) : (
                          <span> {t("data_enrichment.enrichment")}</span>
                        )}
                      </button>
                    </>
                  )}
                  <button
                    disabled={
                      (currentProduct as any)?.enrichmentData === null ||
                      Boolean(!(currentProduct as any)?.enrichmentData?.data)
                    }
                    onClick={(e) => getAiInformation(e)}
                    className="btn btn-success"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRightScrapper"
                    aria-controls="offcanvasRight"
                  >
                    {t("data_enrichment.product_data")}
                  </button>
                </div>
              </div>
              {Boolean((currentProduct as any)?.enrichmentData) &&
                Boolean((currentProduct as any)?.enrichmentData?.data) && (
                  <div
                    className="offcanvas offcanvas-end w-75"
                    tabIndex={-1}
                    id="offcanvasRightScrapper"
                    aria-labelledby="offcanvasRightLabel"
                  >
                    <div className="offcanvas-body pt-0">
                      <div className="row border-bottom sticky-header-offcanvas">
                        <div className="col-6 border-end d-flex justify-content-between align-items-center">
                          <h5 id="offcanvasRightLabel" className="m-0 py-3">
                            {t("data_enrichment.product_data")}
                          </h5>
                          <button
                            style={{ minWidth: "79px" }}
                            className="btn btn-success ms-1 me-2"
                            onClick={(e: any) => refreshAiInformation(e)}
                          >
                            {isLoading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                              />
                            ) : (
                              <span> Refresh</span>
                            )}
                          </button>
                        </div>
                        <div className="col-6">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="m-0 py-3">
                              {t("global.online_information")}
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* <OffcanvasAttributeCallback /> */}
                        <OffcanvasAttributeLeftSide
                          currentProduct={currentProduct}
                          isLoadingTable={isLoadingTable}
                          setCurrentProduct={setCurrentProduct}
                          getProductId={getProductId}
                          closeOffcanvas={closeOffcanvas}
                        />
                        <div className="col-6 mt-3">
                          {(currentProduct as any)?.enrichmentData
                            ?.crawledSites && (
                            <div style={{ marginBottom: "1rem" }}>
                              <label className="d-block">
                                {" "}
                                {t("crawlers.website")}:
                              </label>
                              {(
                                currentProduct as any
                              )?.enrichmentData?.crawledSites?.map(
                                (link: string) => {
                                  return (
                                    <a
                                      className="d-block"
                                      href={link}
                                      target="_blank"
                                    >
                                      {link}
                                    </a>
                                  );
                                }
                              )}
                            </div>
                          )}
                          {(currentProduct as any)?.enrichmentData?.data && (
                            <>
                              {/* <label className="d-block">Data:</label> */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: (currentProduct as any)
                                    ?.enrichmentData?.data,
                                }}
                              />
                              {/* <p>{(currentProduct as any)?.enrichmentData?.data}</p> */}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      )}
      {!isWorkflowUpdate && (
        <>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-sm-flex align-items-center justify-content-between mb-3">
                  <ul className="nav nav-pills nav-fill">
                    <li onClick={() => navBaseData()}>
                      <span className="nav-link cursor-pointer text-dark">
                        {t("products.base_data_update")}
                      </span>
                    </li>
                    <li>
                      <span className="nav-link active">
                        {t("products.attributes_update")}
                      </span>
                    </li>
                    <li onClick={() => navContent()}>
                      <span className="nav-link cursor-pointer text-dark">
                        {t("products.content_update")}
                      </span>
                    </li>
                  </ul>
                  <div className="row  align-items-end" style={{ width: 500 }}>
                    <div className="col-9">
                      <h6 className="m-0 p-0 mb-2">{t("global.export")}:</h6>

                      <Select
                        className="react-select-field"
                        onChange={selectExportHandler}
                        options={exportsList.map((exportItem) => ({
                          label: exportItem.name,
                          value: exportItem.id,
                        }))}
                      />
                    </div>
                    <button
                      className="btn btn-primary word-wrap col-3"
                      onClick={exportClickHandler}
                    >
                      {t("global.add_to_export")}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    onClick={() => backHandle()}
                    className="btn btn-info d-flex align-items-end mb-3"
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    {t("products.back")}
                  </button>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={checkHref()}
                    // href={`${currentProduct?.sourceUrl === null ? "/403" : `${currentProduct?.sourceUrl}`} `}
                  >
                    <button className="btn btn-primary">
                      {t("products.source_url")}
                    </button>
                  </a>
                </div>
                <div className="col-md-12 mb-3 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center mb-3">
                    <div className="d-flex gap-3 align-items-center">
                      <ImageContainer
                        img={currentProduct?.productPictures ?? []}
                      />
                      <div>
                        <h2>{currentProduct?.title}</h2>
                        <p>{currentProduct?.eanCode}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ProductsAttributesActions
        handleClose={handleClose}
        isWorkflowUpdate={
          location?.pathname?.includes("workflow/step4/") ? true : false
        }
        setSelectedProductId={setSelectedProductId}
        selectedProductId={selectedProductId}
        currentProduct={currentProduct as IProductsList}
        setCurrentProduct={
          setCurrentProduct as React.Dispatch<
            SetStateAction<IProductsList | IProducts>
          >
        }
        handleSelectMainCategory={handleSelectMainCategory}
        changeValuesHandler={changeValuesHandler}
      />
      <div className="col-9 d-flex justify-content-end ">
        {totalPages <= 1 ? (
          ""
        ) : (
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
    </>
  );
};
