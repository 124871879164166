import { ActualFileObject } from "filepond";
import React, { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { Pagination } from "../../../../shared/paginations/Paginations";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { IAllStates } from "../../../../store/rootReducer";
import { IExport } from "../../../exports/dto/IExports";
import { ExportService } from "../../../exports/service";
import { WorkflowService } from "../../../workflow/service";
import { IProducts, IProductsList } from "../../dto/IProducts";
import { ProductsService } from "../../service";
import { BaseDataActions } from "./BaseDataActions";
import ImageContainer from "../../../../shared/gallery/ImageContainer";
import changeLocationSlice from "../../../../store/category/changeLocation";
import { GoogleSearchPromptService } from "../../../googleSearchPrompt/service";
import { Spinner, Table } from "react-bootstrap";
import { getCorrectField } from "../../../../shared/functions/Functions";

interface Props {
  isWorkflowUpdate?: boolean;
  selectedProductId?: number;
  setSelectedProductId?: React.Dispatch<SetStateAction<number>> | undefined;
}

export const BaseDataUpdate = ({
  isWorkflowUpdate = false,
  selectedProductId,
}: Props) => {
  const expOrAttPagination = useSelector(
    (state: IAllStates) => state.expOrAttPagination as any
  );
  const [currentTranslations, setCurrentTranslations] = useState<any>({
    en: {
      photos: [],
      documents: [],
      videos: [],
    },
    de: {
      photos: [],
      documents: [],
      videos: [],
    },
    fr: {
      photos: [],
      documents: [],
      videos: [],
    },
    it: {
      photos: [],
      documents: [],
      videos: [],
    },
  });
  const dispatch = useDispatch();
  const { id, productId } = useParams();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const { t } = useTranslation();
  const { state: close }: any = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const workflowData = useSelector((state: IAllStates) => state.workflow);
  const navigate = useNavigate();
  const [variationPagination, setVariationPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [exportsList, setExportsList] = useState<IExport[]>([]);
  const [googleSearchList, setGoogleSearchList] = useState<any[]>([]);
  const [googleSearchPrompt, setGoogleSearchPrompt] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentProduct, setCurrentProduct] = useState<IProductsList>();
  const [files, setFiles] = useState<ActualFileObject[]>([]);
  const [page, setPage] = useState(1);
  const secondViewPagination = useSelector(
    (state: IAllStates) => state.secondViewPagination as any
  );
  const { state: pagination }: any = useLocation();
  const handlePages = (updatePage: number) => {
    setVariationPagination({ ...variationPagination, page: updatePage });
    setPage(updatePage);
  };
  const allFields = (currentProduct as any)?.enrichmentData?.formBuilderData
    ? Object.keys(
        (currentProduct as any)?.enrichmentData?.formBuilderData
      )?.filter((field: string) => field !== "language")
    : [];
  const location = useLocation();
  const tab = "BASE_DATA";
  const [exportData, setExportData] = useState<{
    clientExport: null | number;
  }>({ clientExport: null });
  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >
  ) => {
    // @ts-ignore
    const { value, name } = event.target;
    dispatch(changeLocationSlice.actions.setChangeLocation(true));
    setCurrentProduct((prev: any) => ({ ...prev, [name]: value }));
  };
  const categories = useSelector((state: IAllStates) => state.category);
  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>,
    inputKey: string
  ) => {
    dispatch(changeLocationSlice.actions.setChangeLocation(true));
    setCurrentProduct((prev: any) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
  };
  const checkDisabled = () => {
    let counter = 0;
    allFields.map((item: any, index: number) => {
      if (
        (currentProduct as any)?.enrichmentData?.formBuilderData?.[item]
          ?.insert === true
      ) {
        counter = counter + 1;
      }
    });
    return counter > 0 ? false : true;
  };
  const checkAllFields = () => {
    let counter = 0;
    allFields.map((item: any, index: number) => {
      if (
        (currentProduct as any)?.enrichmentData?.formBuilderData?.[item]
          ?.insert === true
      ) {
        counter = counter + 1;
      } else {
        return setIsCheckedAll(false);
      }
    });
    if (counter === allFields?.length - 1) {
      setIsCheckedAll(true);
    }
  };
  const FillOrReplaceSubmit = (type: string) => {
    ProductsService.enrichFillOrReplaceBaseData(
      Number(currentProduct?.id),
      type,
      (currentProduct as any)?.enrichmentData?.formBuilderData
    )
      .then((response) => {
        SuccessToast(`Success`);
        setIsCheckedAll(false);
        getProductId();
        // setCurrentProduct(response);
      })
      .catch((error) => ErrorToast(error));
  };
  const checkAll = (e: any) => {
    if (e.target.checked) {
      setIsCheckedAll(true);
      allFields?.forEach((field: string) => {
        setCurrentProduct((prev: any) => ({
          ...prev,
          enrichmentData: {
            ...prev?.enrichmentData,
            formBuilderData: {
              ...prev?.enrichmentData?.formBuilderData,
              [field]: {
                ...prev?.enrichmentData?.formBuilderData?.[field],
                insert: true,
              },
            },
          },
        }));
      });
    } else {
      setIsCheckedAll(false);
      allFields?.forEach((field: string) => {
        setCurrentProduct((prev: any) => ({
          ...prev,
          enrichmentData: {
            ...prev?.enrichmentData,
            formBuilderData: {
              ...prev?.enrichmentData?.formBuilderData,
              [field]: {
                ...prev?.enrichmentData?.formBuilderData?.[field],
                insert: false,
              },
            },
          },
        }));
      });
    }
  };
  const checkboxHandler = (e: any) => {
    if (e.target.checked) {
      setCurrentProduct((prev: any) => ({
        ...prev,
        enrichmentData: {
          ...prev?.enrichmentData,
          formBuilderData: {
            ...prev?.enrichmentData?.formBuilderData,
            [e.target.value]: {
              ...prev?.enrichmentData?.formBuilderData?.[e.target.value],
              insert: true,
            },
          },
        },
      }));
      checkAllFields();
    } else {
      setIsCheckedAll(false);
      setCurrentProduct((prev: any) => ({
        ...prev,
        enrichmentData: {
          ...prev?.enrichmentData,
          formBuilderData: {
            ...prev?.enrichmentData?.formBuilderData,
            [e.target.value]: {
              ...prev?.enrichmentData?.formBuilderData?.[e.target.value],
              insert: false,
            },
          },
        },
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent, checkNext = false) => {
    event.preventDefault();
    const translationObject = {
      ["en"]: {
        ["photos"]: [
          ...currentProduct?.translation?.["en"]?.photos,
          ...currentTranslations?.en?.photos,
        ],
        ["documents"]: [
          ...currentProduct?.translation?.["en"]?.documents,
          ...currentTranslations?.en?.documents,
        ],
        ["links"]: [
          ...currentProduct?.translation?.["en"]?.links,
          ...(currentTranslations?.en?.links ?? []),
        ],
        ["videos"]: [
          ...currentProduct?.translation?.["en"]?.videos,
          ...currentTranslations?.en?.videos,
        ],
        ["title"]: currentProduct?.translation?.["en"]?.title,
        ["subtitle"]: currentProduct?.translation?.["en"]?.subtitle,
      },
      ["de"]: {
        ["photos"]: [
          ...currentProduct?.translation?.["de"]?.photos,
          ...currentTranslations?.de?.photos,
        ],
        ["documents"]: [
          ...currentProduct?.translation?.["de"]?.documents,
          ...currentTranslations?.de?.documents,
        ],
        ["videos"]: [
          ...currentProduct?.translation?.["de"]?.videos,
          ...currentTranslations?.de?.videos,
        ],
        ["title"]: currentProduct?.translation?.["de"]?.title,
        ["subtitle"]: currentProduct?.translation?.["de"]?.subtitle,
        ["links"]: [
          ...currentProduct?.translation?.["de"]?.links,
          ...currentTranslations?.de?.links,
        ],
      },
      ["fr"]: {
        ["photos"]: [
          ...currentProduct?.translation?.["fr"]?.photos,
          ...currentTranslations?.fr?.photos,
        ],
        ["documents"]: [
          ...currentProduct?.translation?.["fr"]?.documents,
          ...currentTranslations?.fr?.documents,
        ],
        ["videos"]: [
          ...currentProduct?.translation?.["fr"]?.videos,
          ...currentTranslations?.fr?.videos,
        ],
        ["title"]: currentProduct?.translation?.["fr"]?.title,
        ["subtitle"]: currentProduct?.translation?.["fr"]?.subtitle,
        ["links"]: [
          ...currentProduct?.translation?.["fr"]?.links,
          ...currentTranslations?.fr?.links,
        ],
      },
      ["it"]: {
        ["photos"]: [
          ...currentProduct?.translation?.["it"]?.photos,
          ...currentTranslations?.it?.photos,
        ],
        ["documents"]: [
          ...currentProduct?.translation?.["it"]?.documents,
          ...currentTranslations?.it?.documents,
        ],
        ["videos"]: [
          ...currentProduct?.translation?.["it"]?.videos,
          ...currentTranslations?.it?.videos,
        ],
        ["title"]: currentProduct?.translation?.["it"]?.title,
        ["subtitle"]: currentProduct?.translation?.["it"]?.subtitle,
        ["links"]: [
          ...currentProduct?.translation?.["it"]?.links,
          ...currentTranslations?.it?.links,
        ],
      },
    };
    const workflowIds = {
      productId: productId,
      workflowId: id,
    };
    const formData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append(
      "body",
      JSON.stringify({
        ...currentProduct,
        translation: translationObject,
        ...workflowIds,
        // isWorkflowUpdate ?? workflowIds,
        category: Number(currentProduct?.category)
          ? currentProduct?.category
          : (currentProduct?.category as any)?.id,
        brand: Number(currentProduct?.brand)
          ? currentProduct?.brand
          : (currentProduct?.brand as any)?.id,
      })
    );
    ProductsService.updateProductById(
      formData,
      Number(isWorkflowUpdate ? productId : id),
      isWorkflowUpdate,
      Number(id)
    )
      .then((response) => {
        dispatch(changeLocationSlice.actions.setChangeLocation(false));
        if (isWorkflowUpdate && checkNext) {
          WorkflowService.getNextWorkflow(Number(id))
            .then((response) => {
              if (!response.nextProduct?.id) {
                navigate(`/app/workflow/step3/${id}`);
              } else {
                navigate(
                  `/app/workflow/step3/${response.nextProduct?.id}/${id}`
                );
                window.scrollTo(0, 0);
              }
            })
            .catch((error) => ErrorToast(error));
        }
        SuccessToast(`${t("toasts.successfully_updated")} ${response.title}`);
        setCurrentProduct(response);
      })
      .catch((error) => ErrorToast(error));
  };
  const selectExportHandler = (e: any) => {
    setExportData({ clientExport: +e.value });
  };
  const selectGoogleSearchHandler = (e: any) => {
    setGoogleSearchPrompt(+e.value);
  };
  const submitGoogleSearch = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    GoogleSearchPromptService.enrichProduct({
      productId: currentProduct?.id,
      promptId: googleSearchPrompt,
      titleLanguage: workflowData?.sourceLang ?? "de",
    })
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_created")}`);
        setCurrentProduct((prev: any) => ({
          ...prev,
          enrichmentData: response?.enrichmentData,
        }));
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setIsLoading(false));
  };
  const exportClickHandler = () => {
    ExportService.exportProduct(exportData, +id!)
      .then((res) => {
        SuccessToast(`${t("toasts.successfully_exported")}`);
      })
      .catch((err) => console.log(err));
  };
  const handleClose = () => {
    if (isWorkflowUpdate) {
      navigate(`/app/workflow/step3/${id}`);
    } else {
      navigate("/app/products/base-data", {
        state:
          (location.state as any)?.pagination ??
          secondViewPagination?.pagination,
      });
      // navigate("/app/pending-products/imported", { state: { pagination: pagination?.pagination ?? {} } });
    }
  };
  const navContent = () => {
    if (close?.close === "export") {
      navigate(`/app/products/edit-content/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/edit-content/${id}`, { state: pagination });
    }
  };
  const navAttributes = () => {
    if (close?.close === "export") {
      navigate(`/app/products/attributes/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/attributes/${id}`, { state: pagination });
    }
  };
  // {
  //   navigate(`/app/products/attributes/${id}`, { state: pagination });
  // };
  const checkHref = () => {
    if (
      currentProduct?.sourceUrl === null ||
      currentProduct?.sourceUrl === "" ||
      currentProduct?.sourceUrl === undefined
    ) {
      return "/403";
    } else if (
      currentProduct?.sourceUrl?.includes("https://") ||
      currentProduct?.sourceUrl?.includes("http://")
    ) {
      return currentProduct?.sourceUrl;
    } else {
      return `https://${currentProduct?.sourceUrl}`;
    }
  };
  const getProductId = () => {
    id &&
      ProductsService.getProductById(
        isWorkflowUpdate ? Number(selectedProductId) : +id,
        tab
      )
        .then((response) => {
          setCurrentProduct({
            ...response,
            translation: {
              ...response?.translation,
              de: {
                ...response?.translation?.de,
                photos:
                  response?.translation?.de?.photos?.length >= 1
                    ? response?.translation?.de?.photos?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                videos:
                  response?.translation?.de?.videos?.length >= 1
                    ? response?.translation?.de?.videos?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                links:
                  response?.translation?.de?.links?.length >= 1
                    ? response?.translation?.de?.links?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                documents:
                  response?.translation?.de?.documents?.length >= 1
                    ? response?.translation?.de?.documents?.filter(
                        (item: any, index: number) => index === 0
                      )
                    : [
                        {
                          block: null,
                          link: "",
                        },
                      ],
              },
              en: {
                ...response?.translation?.en,
                photos:
                  response?.translation?.en?.photos?.length >= 1
                    ? response?.translation?.en?.photos?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                videos:
                  response?.translation?.en?.videos?.length >= 1
                    ? response?.translation?.en?.videos?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                links:
                  response?.translation?.en?.links?.length >= 1
                    ? response?.translation?.en?.links?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                documents:
                  response?.translation?.en?.documents?.length >= 1
                    ? response?.translation?.en?.documents?.filter(
                        (item: any, index: number) => index === 0
                      )
                    : [
                        {
                          block: null,
                          link: "",
                        },
                      ],
              },
              fr: {
                ...response?.translation?.fr,
                photos:
                  response?.translation?.fr?.photos?.length >= 1
                    ? response?.translation?.fr?.photos?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                videos:
                  response?.translation?.fr?.videos?.length >= 1
                    ? response?.translation?.fr?.videos?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                links:
                  response?.translation?.fr?.links?.length >= 1
                    ? response?.translation?.fr?.links?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                documents:
                  response?.translation?.fr?.documents?.length >= 1
                    ? response?.translation?.fr?.documents?.filter(
                        (item: any, index: number) => index === 0
                      )
                    : [
                        {
                          block: null,
                          link: "",
                        },
                      ],
              },
              it: {
                ...response?.translation?.it,
                photos:
                  response?.translation?.it?.photos?.length >= 1
                    ? response?.translation?.it?.photos?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                videos:
                  response?.translation?.it?.videos?.length >= 1
                    ? response?.translation?.it?.videos?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                links:
                  response?.translation?.it?.links?.length >= 1
                    ? response?.translation?.it?.links?.filter(
                        (item: string, index: number) => index === 0
                      )
                    : [""],
                documents:
                  response?.translation?.it?.documents?.length >= 1
                    ? response?.translation?.it?.documents?.filter(
                        (item: any, index: number) => index === 0
                      )
                    : [
                        {
                          block: null,
                          link: "",
                        },
                      ],
              },
            },
          });
          setCurrentTranslations({
            de: {
              photos:
                response?.translation?.de?.photos?.length > 1
                  ? response?.translation?.de?.photos?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
              documents:
                response?.translation?.de?.documents?.length > 1
                  ? response?.translation?.de?.documents?.filter(
                      (item: any, index: number) => {
                        if (index > 0) {
                          return item ?? "";
                        }
                      }
                    )
                  : [],
              videos:
                response?.translation?.de?.videos?.length > 1
                  ? response?.translation?.de?.videos?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
              links:
                response?.translation?.de?.links?.length > 1
                  ? response?.translation?.de?.links?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
            },
            en: {
              photos:
                response?.translation?.en?.photos?.length > 1
                  ? response?.translation?.en?.photos?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
              documents:
                response?.translation?.en?.documents?.length > 1
                  ? response?.translation?.en?.documents?.filter(
                      (item: any, index: number) => index > 0
                    )
                  : [],
              videos:
                response?.translation?.en?.videos?.length > 1
                  ? response?.translation?.en?.videos?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
              links:
                response?.translation?.en?.links?.length > 1
                  ? response?.translation?.en?.links?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
            },
            fr: {
              photos:
                response?.translation?.fr?.photos?.length > 1
                  ? response?.translation?.fr?.photos?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
              documents:
                response?.translation?.fr?.documents?.length > 1
                  ? response?.translation?.fr?.documents?.filter(
                      (item: any, index: number) => index > 0
                    )
                  : [],
              videos:
                response?.translation?.fr?.videos?.length > 1
                  ? response?.translation?.fr?.videos?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
              links:
                response?.translation?.fr?.links?.length > 1
                  ? response?.translation?.fr?.links?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
            },
            it: {
              photos:
                response?.translation?.it?.photos?.length > 1
                  ? response?.translation?.it?.photos?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
              documents:
                response?.translation?.it?.documents?.length > 1
                  ? response?.translation?.it?.documents?.filter(
                      (item: any, index: number) => index > 0
                    )
                  : [],
              videos:
                response?.translation?.it?.videos?.length > 1
                  ? response?.translation?.it?.videos?.filter(
                      (item: string, index: number) => index > 0
                    )
                  : [],
              links:
                response?.translation?.it?.links?.length > 1
                  ? response?.translation?.it?.links?.filter(
                      (item: any, index: number) => index > 0
                    )
                  : [],
            },
          });
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            navigate("/error-404");
          } else {
            ErrorToast(error);
            navigate(-1);
          }
        });
  };
  useEffect(() => {
    id && tab && getProductId();
  }, [id, selectedProductId]);
  useEffect(() => {
    ExportService.getAllExports({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setExportsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    GoogleSearchPromptService.getAllPrompts({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setGoogleSearchList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const goBack = () => {
    if (
      location?.pathname?.includes("/app/pending-products/imported/base-data/")
    ) {
      // navigate('/app/pending-products/imported');
      navigate(-1);
    } else {
      navigate(-1);
    }
  };
  const backHandle = () => {
    if (close?.close === "export") {
      navigate(`/app/export/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
      // goBack()
    } else if (close?.close === "attributes") {
      navigate(`/admin/attributes/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
    } else if (handleClose) {
      handleClose();
    } else {
      goBack();
    }
  };
  return (
    <>
      {isWorkflowUpdate && (
        <>
          <div className="d-flex  align-items-center mb-3">
            <div className="d-flex gap-3 align-items-center">
              <ImageContainer img={currentProduct?.productPictures ?? []} />
              {/* <GalleryCardPreview
                img={
                  currentProduct?.productPictures[0]?.path ||
                  "/images/jk-placeholder-image.jpg"
                }
              /> */}
              {/* <CustomImageHandler
            classes="avatar-lg rounded-circle me-2"
            path={currentProduct?.productPictures[1]?.path ?? ""}
            alt="User logo"
          /> */}
              <div>
                <h2>{currentProduct?.title}</h2>
                <p style={{ marginBottom: "0.5rem" }}>
                  {currentProduct?.eanCode}
                </p>
                <p style={{ marginBottom: "0.5rem" }}>
                  {currentProduct?.brand?.name ?? "-"}
                </p>
                <div className="d-flex">
                  {accessRights?.includes(
                    "data_enrichment_workflow_single_product"
                  ) && (
                    <>
                      <Select
                        className="react-select-field w-250"
                        placeholder={"Google search prompt"}
                        onChange={selectGoogleSearchHandler}
                        // isClearable={true}
                        options={googleSearchList.map((exportItem) => ({
                          label: exportItem.title,
                          value: exportItem.id,
                        }))}
                      />
                      <button
                        style={{ minWidth: "141px" }}
                        className="btn btn-primary ms-1 me-2"
                        disabled={googleSearchPrompt === 0}
                        onClick={(e: any) => submitGoogleSearch(e)}
                      >
                        {isLoading ? (
                          <Spinner animation="border" size="sm" role="status" />
                        ) : (
                          <span> {t("data_enrichment.enrichment")}</span>
                        )}
                      </button>
                    </>
                  )}
                  <button
                    disabled={
                      (currentProduct as any)?.enrichmentData === null ||
                      Boolean(!(currentProduct as any)?.enrichmentData?.data)
                    }
                    className="btn btn-success"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRightScrapper"
                    aria-controls="offcanvasRight"
                  >
                    {t("data_enrichment.product_data")}
                  </button>
                </div>
              </div>
              {Boolean((currentProduct as any)?.enrichmentData) &&
                Boolean((currentProduct as any)?.enrichmentData?.data) && (
                  <div
                    className="offcanvas offcanvas-end w-75"
                    tabIndex={-1}
                    id="offcanvasRightScrapper"
                    aria-labelledby="offcanvasRightLabel"
                  >
                    <div className="offcanvas-body pt-0">
                      <div className="row border-bottom sticky-header-offcanvas">
                        <div className="col-6 border-end d-flex align-items-center">
                          <h5 id="offcanvasRightLabel" className="m-0 py-3">
                            {t("data_enrichment.product_data")}
                          </h5>
                        </div>
                        <div className="col-6">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="m-0 py-3">
                              {t("global.online_information")}
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 border-end pt-3">
                          <div className="table-responsive">
                            <Table className="mb-0 align-middle table-bordered">
                              <thead className="table-light">
                                <tr>
                                  <th className="" scope="col">{`${t(
                                    "global.field"
                                  )}`}</th>
                                  <th className="p-0" scope="col">
                                    <span
                                      className="d-block border-bottom"
                                      style={{ padding: ".375rem .3rem" }}
                                    >
                                      {`${t("global.old_value")}`}
                                    </span>
                                    <span
                                      className="d-block"
                                      style={{ padding: ".375rem .3rem" }}
                                    >
                                      {`${t("global.new_value")}`}
                                    </span>
                                  </th>
                                  <th className="vertical-middle text-center">
                                    <input
                                      className="m-auto form-check-input m-0 cursor-pointer"
                                      // value={maxId}
                                      checked={isCheckedAll}
                                      type="checkbox"
                                      onChange={checkAll}
                                    />
                                  </th>
                                </tr>
                              </thead>
                              {(currentProduct as any)?.enrichmentData
                                ?.formBuilderData && (
                                <tbody>
                                  {allFields?.map((item: string) => {
                                    return (
                                      <tr>
                                        <td
                                          className="text-nowrap text-start"
                                          style={{
                                            backgroundColor: "#f3f6f9",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {getCorrectField(item, t)}
                                        </td>
                                        <td className="text-start border-left p-0">
                                          <span
                                            className="d-block border-bottom"
                                            style={{
                                              padding: ".75rem .6rem",
                                              backgroundColor: "#f7d0d0",
                                            }}
                                          >
                                            {
                                              (currentProduct as any)
                                                ?.enrichmentData
                                                ?.formBuilderData?.[item]
                                                ?.oldValue
                                            }
                                          </span>
                                          <span
                                            className="d-block"
                                            style={{
                                              padding: ".75rem .6rem",
                                              backgroundColor: "#e1f7da",
                                            }}
                                          >
                                            {
                                              (currentProduct as any)
                                                ?.enrichmentData
                                                ?.formBuilderData?.[item]
                                                ?.newValue
                                            }
                                          </span>
                                        </td>
                                        <td className="">
                                          <input
                                            type="checkbox"
                                            className="form-check-input m-0 cursor-pointer"
                                            onChange={(e) => checkboxHandler(e)}
                                            value={item}
                                            checked={
                                              (currentProduct as any)
                                                ?.enrichmentData
                                                ?.formBuilderData?.[item]
                                                ?.insert
                                            }
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              )}
                            </Table>
                          </div>
                          <div className="d-flex align-items-center justify-content-end mt-3">
                            <button
                              onClick={() => FillOrReplaceSubmit("fill")}
                              className="btn btn-info me-2"
                              disabled={
                                (currentProduct as any)?.enrichmentData
                                  ?.formBuilderData
                                  ? checkDisabled()
                                  : true
                              }
                            >
                              {t("global.fill")}
                            </button>

                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                FillOrReplaceSubmit("fillAndReplace")
                              }
                              disabled={
                                (currentProduct as any)?.enrichmentData
                                  ?.formBuilderData
                                  ? checkDisabled()
                                  : true
                              }
                            >
                              {t("global.fill_replace")}
                            </button>
                          </div>
                        </div>
                        <div className="col-6 mt-3">
                          {(currentProduct as any)?.enrichmentData
                            ?.crawledSites && (
                            <div style={{ marginBottom: "1rem" }}>
                              <label className="d-block">
                                {" "}
                                {t("crawlers.website")}:
                              </label>
                              {(
                                currentProduct as any
                              )?.enrichmentData?.crawledSites?.map(
                                (link: string) => {
                                  return (
                                    <a
                                      className="d-block"
                                      href={link}
                                      target="_blank"
                                    >
                                      {link}
                                    </a>
                                  );
                                }
                              )}
                            </div>
                          )}
                          {(currentProduct as any)?.enrichmentData?.data && (
                            <>
                              {/* <label className="d-block">Data:</label> */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: (currentProduct as any)
                                    ?.enrichmentData?.data,
                                }}
                              />
                              {/* <p>{(currentProduct as any)?.enrichmentData?.data}</p> */}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <a
              rel="noreferrer"
              target="_blank"
              // href={`${currentProduct?.sourceUrl === null ? "/403" : `${currentProduct?.sourceUrl}`} `}
              href={checkHref()}
            >
              <button className="btn btn-primary">
                {t("products.source_url")}
              </button>
            </a>
          </div>
        </>
      )}
      {!isWorkflowUpdate && (
        <>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-sm-flex align-items-center justify-content-between mb-3">
                  <ul className="nav nav-pills nav-fill">
                    <li className="nav-item">
                      <span className="nav-link active">
                        {t("products.base_data_update")}
                      </span>
                    </li>
                    <li onClick={() => navAttributes()}>
                      <span className="nav-link cursor-pointer text-dark">
                        {t("products.attributes_update")}
                      </span>
                    </li>
                    <li onClick={() => navContent()}>
                      <span className="nav-link cursor-pointer text-dark">
                        {t("products.content_update")}
                      </span>
                    </li>
                  </ul>
                  <div
                    className="row  align-items-end"
                    style={{ width: 500, position: "relative", zIndex: "1000" }}
                  >
                    <div className="col-9">
                      <h6 className="m-0 p-0 mb-2">{t("global.export")}:</h6>
                      <Select
                        className="react-select-field"
                        onChange={selectExportHandler}
                        options={exportsList.map((exportItem) => ({
                          label: exportItem.name,
                          value: exportItem.id,
                        }))}
                      />
                    </div>
                    <button
                      className="btn btn-primary word-wrap col-3"
                      onClick={exportClickHandler}
                    >
                      {t("global.add_to_export")}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    onClick={() => backHandle()}
                    className="btn btn-info d-flex align-items-center mb-3"
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    {t("products.back")}
                  </button>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    // href={`${currentProduct?.sourceUrl === null ? "/403" : `${currentProduct?.sourceUrl}`} `}
                    href={checkHref()}
                  >
                    <button className="btn btn-primary">
                      {t("products.source_url")}
                    </button>
                  </a>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="d-flex gap-3 align-items-center">
                    <ImageContainer
                      img={currentProduct?.productPictures ?? []}
                    />
                    {/* <GalleryCardPreview
                img={
                  currentProduct?.productPictures[0]?.path ||
                  "/images/jk-placeholder-image.jpg"
                }
              /> */}
                    <div>
                      <h2>{currentProduct?.title}</h2>
                      <p>{currentProduct?.eanCode}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <BaseDataActions
        handleClose={handleClose}
        isEdit={true}
        currentProduct={currentProduct as IProductsList}
        setCurrentProduct={
          setCurrentProduct as React.Dispatch<
            SetStateAction<IProductsList | IProducts>
          >
        }
        currentTranslations={currentTranslations as any}
        setCurrentTranslations={setCurrentTranslations}
        changeHandler={changeHandler}
        handleSelectMainCategory={handleSelectMainCategory}
        files={files}
        setFiles={setFiles}
        handleSubmit={handleSubmit}
        categories={categories}
      />
      <div className="col-9 d-flex justify-content-end ">
        {totalPages <= 1 ? (
          ""
        ) : (
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
    </>
  );
};
