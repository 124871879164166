import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import ArrowIcon from "../../../shared/icons/down-arrow.svg";
import Close from "../../../shared/icons/close.svg";
import { ClientService } from "../../clients/service";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { AttributesService } from "../../attributes/service";
import { IAttributes } from "../../attributes/names/dto/IAttributes";
import { BlockService } from "../../block/service";
import { IBlock } from "../../block/dto/IBlock";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { DocumentBlockService } from "../../blockDocument/service";
import { useTranslation } from "react-i18next";
import { ConfirmDeleteColumn } from "../../../shared/components/ConfrimDeleteCoumn";
import { EmptyState } from "../../../shared/components/EmptyState";
import { EnumService } from "../../../store/enums/enumService";
import enumsSlice from "../../../store/enums/enumsRedux";

export const MappingImportClients = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [identificator, setIdentificator] = useState<number[]>([1]);
  const [selectedColumn, setSelectedColumn] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const handleShowDelete = () => setShowDelete(true);
  const translationValue = [
    "title",
    "subtitle",
    "description",
    "intro",
    "bullets",
    "photo",
    "video",
    "link",
    "document",
  ];
  const separators = ["without", ";", ",", "|"];
  const getCorrectSeparator = (separator: string, t: any) => {
    switch (separator) {
      case ";":
        return `${t(`separator.${separator}`)}`;
      case ",":
        return `${t(`separator.${separator}`)}`;
      case "|":
        return `${t(`separator.${separator}`)}`;
      case "without":
        return `${t(`separator.${separator}`)}`;
      default:
        return `NULL - ${separator}`;
    }
  };
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [blockList, setBlockList] = useState<IBlock[] | undefined>();
  const [documentBlockList, setDocumentBlockList] = useState<
    IBlock[] | undefined
  >();
  const addedBlockField = [{ value: 0, label: t("document_block.main_value") }];
  const [attributesList, setAttributesList] = useState<IAttributes[]>([]);
  const [mappingArray, setMappingArray] = useState<any[]>([]);
  const [columnId, setColumnId] = useState(0);
  const navigate = useNavigate();
  const uniqueOptions = [
    "ean",
    "article_number",
    "sap_id",
    "category",
    "brand",
    "source_url",
    "pharma_code",
  ];
  function handleSelectSingle2(selectedSingle: any, index: number) {
    setMappingArray((prev: any) => {
      const newItems = [...prev];
      newItems[index] = {
        ...newItems[index],
        ["langauge"]: selectedSingle?.map(
          (selectedSingle: { value: string }) => selectedSingle?.value
        ),
      };
      return newItems;
    });
  }
  const functionForCorrectFields = (item: any): JSX.Element[] => {
    const arr = item?.translations?.map((differentLang: any) =>
      Object?.keys(differentLang)?.filter((key: any) =>
        translationValue?.includes(key)
      )
    );
    return (
      item?.translations?.map(
        (field: any, index: number) =>
          arr[0]?.map((colName: string, inx: number) => (
            <td
              key={`${colName}-${index}-${inx}`}
              style={{
                backgroundColor: `${
                  field?.[colName]?.isLoadedFromDatabase ? "#f8ed62" : "#90EE90"
                }`,
              }}
            >
              {Array?.isArray(field?.[colName]?.value)
                ? field?.[colName]?.value?.map((item: any) => {
                    return (
                      <span className="d-block">{item?.link ?? item}</span>
                    );
                  })
                : field?.[colName]?.value ?? ""}
            </td>
          )) ?? []
      ) ?? []
    );
  };
  const filterArrayDocumentValue = (arr1: any[], arr2: any[]): any[] => {
    // Mapa za brojanje pojavljivanja elemenata u arr2
    const countMap = new Map<any, number>();
    // Mapa sa brojem pojavljivanja elemenata iz arr2
    for (const item of arr2) {
      if (countMap.has(item)) {
        countMap.set(item, countMap.get(item)! + 1);
      } else {
        countMap.set(item, 1);
      }
    }
    // Uklanja elemente koji se pojavljuju 4 puta u arr2
    return arr1?.filter((item) => countMap.get(item) !== 4);
  };
  //------------------------------------------------------------------------------
  const handleSelectMapping = (
    selectedSingle: SingleValue<{ label: string; value: any }>,
    index: number,
    field: string,
    columnName = ""
  ) => {
    if (selectedSingle?.value === undefined) {
      field === "mapTo"
        ? setMappingArray((prev: any) => {
            const newItems = [...prev];
            newItems[index] = {
              ...newItems[index],
              ["mapTo"]: null,
              ["value"]: null,
              ["language"]: null,
            };
            return newItems;
          })
        : setMappingArray((prev: any) => {
            const newItems = [...prev];
            newItems[index] = {
              ...newItems[index],
              [field]: null,
            };
            return newItems;
          });
    } else {
      setMappingArray((prev: any) => {
        const newItems = [...prev];
        newItems[index] = {
          ...newItems[index],
          [field]: selectedSingle?.value,
        };
        return newItems;
      });
    }
    if (columnName === "identificator") {
      const lastValue = identificator?.length;
      if (identificator[lastValue - 1] === selectedSingle?.value) {
        identificator?.push(selectedSingle?.value + 1);
      }
    }
  };
  function capitalizeFirstLetter(str: string) {
    if (!str) return str;
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  }
  useEffect(() => {
    DocumentBlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        const res = data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        const merged = [...addedBlockField, ...res];
        setDocumentBlockList(merged as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const languageVisible = (item: string) => {
    if (
      item === "ean" ||
      item === "article_number" ||
      item === "sap_id" ||
      item === "category" ||
      item === "brand" ||
      item === "source_url" ||
      item === "pharma_code"
    ) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    AttributesService.getAllAttributes({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const res = response?.data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.title,
          };
        });
        setAttributesList(res as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    BlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        const resBlock = data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setBlockList(resBlock as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const deleteHandlerColumn = (inx: number) => {
    const removeItem = mappingArray?.filter(
      (item: any, index: number) => index !== inx
    );
    setMappingArray(removeItem);
  };
  const findUniqueInArray1 = (arr1: any[], arr2: any[]) => {
    return arr1?.filter((item) => !arr2?.includes(item));
  };
  const findUniqueInArrayWithValue = (arr1: any[], arr2: any[]) => {
    return arr1.filter((item) => !arr2?.includes(item?.value));
  };
  const findUniqueInLangauge = (arr1: any[], arr2: any[]) => {
    const arrCorrect = arr2?.map((item: any) => {
      return item?.language;
    });
    return arr1?.filter((item) => !arrCorrect?.includes(item));
  };
  const funcForRepeatLanguageField = (item: any) => {
    const sameFields = (mappingArray as any)?.filter(
      (itemMap: any) => itemMap.mapTo?.toLowerCase() == item?.mapTo
    );
    const allLangaugeFields = sameFields?.filter(
      (filed: any) => filed?.value === item?.value
    );
    if (allLangaugeFields?.length === 0) {
      return (translationLanguageEnum as any)?.map((language: string) => ({
        value: language,
        label: getCorrectLanguage(language, t),
      }));
    } else {
      return findUniqueInLangauge(translationLanguageEnum, allLangaugeFields);
    }
  };
  const funcForLanguageField = (item: any) => {
    const currentField = item?.mapTo;
    const sameFields = (mappingArray as any)?.filter(
      (itemMap: any) => itemMap.mapTo?.toLowerCase() == currentField
    );
    if (currentField === "block") {
      const allValueFields = sameFields
        ?.filter((item: any) => item?.value !== null)
        ?.map((item: any) => {
          return item?.value;
        });
      return (blockList as any)?.map((item: any) => ({
        value: item?.value,
        label: item?.label,
      }));
    } else if (currentField === "document") {
      const allValueFields = sameFields
        ?.filter((item: any) => item?.value !== null)
        ?.map((item: any) => {
          return item?.value;
        });
      return (documentBlockList as any)?.map((item: any) => ({
        value: item?.value,
        label: item?.label,
      }));
    } else if (currentField === "attribute_new") {
      const allValueFields = sameFields
        ?.filter((item: any) => item?.value !== null)
        ?.map((item: any) => {
          return item?.value;
        });
      if (allValueFields?.length === 0) {
        return identificator;
      } else {
        return filterArrayDocumentValue(identificator as any[], allValueFields);
      }
    } else if (currentField === "attribute") {
      return (attributesList as any)?.map((item: any) => ({
        value: item?.value,
        label: item?.label,
      }));
    } else {
      const allLangaugeFields = sameFields?.map((item: any) => {
        return item?.language;
      });
      if (allLangaugeFields?.length === 0) {
        return (translationLanguageEnum as any)?.map((language: string) => ({
          value: language,
          label: getCorrectLanguage(language, t),
        }));
      } else {
        return findUniqueInArray1(translationLanguageEnum, allLangaugeFields);
      }
    }
  };
  const getFilteredOptions = (backArr: string[]) => {
    const selectedValues = mappingArray
      ? mappingArray.map((item) => item.mapTo).filter(Boolean)
      : [];
    return backArr.map((field: string) => ({
      value: field.toLowerCase(),
      label: capitalizeFirstLetter(field)?.replace("_", " "),
    }));
    // .filter((option) => {
    //   return (
    //     !uniqueOptions.includes(option.value) ||
    //     !selectedValues.includes(option.value)
    //   );
    // });
  };
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (id) {
      ClientService.updateMappingImportClientById(+id, { fields: mappingArray })
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_updated")} `);
        })
        .catch((error) => ErrorToast(error));
    }
  };

  useEffect(() => {
    id &&
      ClientService.getImportMapping(+id)
        .then((response: any) => {
          setMappingArray(response);
          const sameFields = (response as any)?.filter(
            (itemMap: any) => itemMap.mapTo?.toLowerCase() == "attribute_new"
          );
          const numbers = sameFields?.map((item: any) => Number(item?.value));
          if (!numbers[0]) {
            return;
          } else {
            let uniqueSortedNumbers = [
              ...(new Set(numbers) as unknown as number[]),
            ].sort((a, b) => a - b);

            let nextNumber =
              uniqueSortedNumbers[uniqueSortedNumbers.length - 1] + 1;
            uniqueSortedNumbers.push(nextNumber);
            if (uniqueSortedNumbers) setIdentificator(uniqueSortedNumbers);
          }
        })
        .catch((error: any) => ErrorToast(error));
  }, [id]);
  useEffect(() => {
    EnumService.getEnums().then((data) => {
      dispatch(enumsSlice.actions.setEnums(data));
    });
  }, []);
  return (
    <>
      <Card>
        <Card.Body className="">
          <Card.Header className="border-top-0  border-bottom-0 sticky-header">
            <div className="col-12  d-flex justify-content-end ">
              <Button
                variant="info"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {`${t("block.back")}`}
              </Button>
              {!!mappingArray?.length && (
                <Button
                  variant="success"
                  className="ms-3"
                  onClick={(e) => submitHandler(e)}
                >
                  {t("global.save")}
                </Button>
              )}
            </div>
          </Card.Header>
          <Row>
            <Col sm={12}>
              {!!mappingArray?.length ? (
                <Row>
                  {mappingArray?.map((item: any, index: number) => (
                    <div className="col-md-2 d-flex flex-column my-3 mapping-import">
                      <div className="d-flex justify-content-end">
                        <img
                          src={Close}
                          className="my-1 cursor-pointer"
                          onClick={() => {
                            handleShowDelete();
                            setSelectedColumn(item);
                            setColumnId(index);
                          }}
                          alt="close"
                        />
                      </div>
                      <textarea
                        value={item?.name}
                        className="w-100"
                        rows={1}
                        style={{
                          width: "100%",
                          height: "28px",
                          overflowY: "hidden",
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                        }}
                        disabled
                      />
                      <img
                        src={ArrowIcon}
                        style={{ height: "35px" }}
                        className="my-1"
                        alt="arrow-icon"
                      />
                      <Select
                        key={item?.mapTo}
                        styles={{
                          control: (base: any) => ({
                            ...base,
                            height: 30,
                            minHeight: 30,
                            backgroundColor: `${
                              item?.mapTo === null ? "#ffff89" : "#ffffff"
                            }`,
                          }),
                        }}
                        className="my-2"
                        theme={themeSelect}
                        placeholder={"Mapping value"}
                        isClearable={true}
                        defaultValue={
                          item?.mapTo !== null && {
                            value: item?.mapTo.toLowerCase(),
                            label: capitalizeFirstLetter(item?.mapTo)?.replace(
                              "_",
                              " "
                            ),
                          }
                        }
                        onChange={(e) => {
                          handleSelectMapping(
                            e as any,
                            index as number,
                            "mapTo"
                          );
                        }}
                        options={getFilteredOptions(item.fields)}
                      />
                      {item?.mapTo === "attribute" ? (
                        <>
                          <Select
                            key={`attribute-${index}`}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: 30,
                                minHeight: 30,
                                backgroundColor: `${
                                  item?.value === null ? "#ffff89" : "#ffffff"
                                }`,
                              }),
                            }}
                            className="my-2"
                            defaultValue={attributesList.find(
                              (attribute: any) =>
                                attribute?.value == item?.value
                            )}
                            theme={themeSelect}
                            placeholder={"Attribute"}
                            isClearable={true}
                            onChange={(e) => {
                              handleSelectMapping(
                                e as any,
                                index as number,
                                "value"
                              );
                            }}
                            options={
                              funcForLanguageField && funcForLanguageField(item)
                            }
                          />
                          {true && (
                            <Select
                              key={`${item?.mapTo}-language-${index}`}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    !item?.language ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.language && {
                                  value: item?.language,
                                  label: getCorrectLanguage(item?.language, t),
                                }
                              }
                              theme={themeSelect}
                              placeholder={"Language"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "language"
                                );
                              }}
                              options={translationLanguageEnum?.map(
                                (language: string) => ({
                                  value: language,
                                  label: getCorrectLanguage(language, t),
                                })
                              )}
                              // options={(
                              //   funcForRepeatLanguageField &&
                              //   funcForRepeatLanguageField(item)
                              // )?.map((language: string) => ({
                              //   value: language,
                              //   label: getCorrectLanguage(language, t),
                              // }))}
                            />
                          )}
                          {true && (
                            <Select
                              key={`${item?.mapTo}-separator-${index}`}
                              // isClearable={true}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${"#ffffff"}`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.separator
                                  ? {
                                      value: item?.separator,
                                      label: getCorrectSeparator(
                                        item?.separator,
                                        t
                                      ),
                                    }
                                  : {
                                      value: null,
                                      label: getCorrectSeparator("without", t),
                                    }
                              }
                              theme={themeSelect}
                              placeholder={"Separator"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "separator"
                                );
                              }}
                              options={(separators as any)?.map(
                                (separator: string) => ({
                                  value: separator,
                                  label: getCorrectSeparator(separator, t),
                                })
                              )}
                            />
                          )}
                        </>
                      ) : item?.mapTo === "photo" ||
                        item?.mapTo === "video" ||
                        item?.mapTo === "link" ? (
                        <Select
                          key={`${item?.mapTo}-${index}`}
                          // styles={customStyles}
                          styles={{
                            control: (base: any) => ({
                              ...base,
                              height: 30,
                              minHeight: 30,
                              backgroundColor: `${
                                !item?.language ? "#ffff89" : "#ffffff"
                              }`,
                            }),
                          }}
                          className="my-2"
                          defaultValue={
                            item?.language && {
                              value: item?.language,
                              label: getCorrectLanguage(item?.language, t),
                            }
                          }
                          theme={themeSelect}
                          placeholder={"Language"}
                          onChange={(e) => {
                            handleSelectMapping(
                              e as any,
                              index as number,
                              "language"
                            );
                          }}
                          options={(translationLanguageEnum as any)?.map(
                            (language: string) => ({
                              value: language,
                              label: getCorrectLanguage(language, t),
                            })
                          )}
                        />
                      ) : item?.mapTo === "block" ? (
                        <>
                          <Select
                            key={`block-main-${index}`}
                            // styles={customStyles}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: 30,
                                minHeight: 30,
                                backgroundColor: `${
                                  item?.value === null ? "#ffff89" : "#ffffff"
                                }`,
                              }),
                            }}
                            className="react-select-field my-2"
                            theme={themeSelect}
                            defaultValue={(blockList as any).find(
                              (block: any) => block?.value == item?.value
                            )}
                            placeholder={"Block"}
                            isClearable={true}
                            onChange={(e) => {
                              handleSelectMapping(
                                e as any,
                                index as number,
                                "value"
                              );
                            }}
                            options={
                              funcForLanguageField && funcForLanguageField(item)
                            }
                          />
                          {true && (
                            <Select
                              key={`${item?.mapTo}-${index}`}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    !item?.language ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.language && {
                                  value: item?.language,
                                  label: getCorrectLanguage(item?.language, t),
                                }
                              }
                              theme={themeSelect}
                              placeholder={"Language"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "language"
                                );
                              }}
                              options={translationLanguageEnum?.map(
                                (language: string) => ({
                                  value: language,
                                  label: getCorrectLanguage(language, t),
                                })
                              )}
                              // options={(
                              //   funcForRepeatLanguageField &&
                              //   funcForRepeatLanguageField(item)
                              // )?.map((language: string) => ({
                              //   value: language,
                              //   label: getCorrectLanguage(language, t),
                              // }))}
                            />
                          )}
                        </>
                      ) : item?.mapTo === "attribute_new" ? (
                        <>
                          <Select
                            key={`attribute-new-${index}`}
                            // styles={customStyles}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: 30,
                                minHeight: 30,
                                backgroundColor: `${
                                  item?.value === null ? "#ffff89" : "#ffffff"
                                }`,
                              }),
                            }}
                            className="react-select-field my-2"
                            theme={themeSelect}
                            // defaultValue={(identificator as any).find(
                            //   (block: any) => block == item?.value
                            // )}
                            defaultValue={
                              !!item?.value && {
                                value: Number(item?.value),
                                label: item?.value,
                              }
                            }
                            placeholder={"Identificator"}
                            isClearable={true}
                            onChange={(e) => {
                              handleSelectMapping(
                                e as any,
                                index as number,
                                "value",
                                "identificator"
                              );
                            }}
                            options={(
                              funcForLanguageField && funcForLanguageField(item)
                            )?.map((identificator: string) => ({
                              value: identificator,
                              label: identificator,
                            }))}
                          />
                          {true && (
                            <Select
                              key={`${item?.mapTo}-language-${index}`}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    !item?.language ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.language && {
                                  value: item?.language,
                                  label: getCorrectLanguage(item?.language, t),
                                }
                              }
                              theme={themeSelect}
                              placeholder={"Language"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "language"
                                );
                              }}
                              options={translationLanguageEnum?.map(
                                (language: string) => ({
                                  value: language,
                                  label: getCorrectLanguage(language, t),
                                })
                              )}
                              // options={(
                              //   funcForRepeatLanguageField &&
                              //   funcForRepeatLanguageField(item)
                              // )?.map((language: string) => ({
                              //   value: language,
                              //   label: getCorrectLanguage(language, t),
                              // }))}
                            />
                          )}
                          {true && (
                            <Select
                              key={`${item?.mapTo}-separator-${index}`}
                              // isClearable={true}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${"#ffffff"}`,
                                }),
                              }}
                              className="my-2"
                              defaultValue={
                                item?.separator
                                  ? {
                                      value: item?.separator,
                                      label: getCorrectSeparator(
                                        item?.separator,
                                        t
                                      ),
                                    }
                                  : {
                                      value: null,
                                      label: getCorrectSeparator("without", t),
                                    }
                              }
                              theme={themeSelect}
                              placeholder={"Separator"}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "separator"
                                );
                              }}
                              options={(separators as any)?.map(
                                (separator: string) => ({
                                  value: separator,
                                  label: getCorrectSeparator(separator, t),
                                })
                              )}
                            />
                          )}
                        </>
                      ) : item?.mapTo === "document" ? (
                        <>
                          {
                            <Select
                              key={`document-${index}`}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30,
                                  backgroundColor: `${
                                    item?.value === null ? "#ffff89" : "#ffffff"
                                  }`,
                                }),
                              }}
                              className="react-select-field my-2"
                              theme={themeSelect}
                              // defaultValue={
                              //   documentBlockList &&
                              //   (documentBlockList as any).find(
                              //     (block: any) => block?.value == item?.value
                              //   )
                              // }

                              defaultValue={
                                documentBlockList &&
                                documentBlockList.find(
                                  (attribute: any) =>
                                    attribute?.value == item?.value
                                )
                              }
                              placeholder={capitalizeFirstLetter(item?.mapTo)}
                              isClearable={true}
                              onChange={(e) => {
                                handleSelectMapping(
                                  e as any,
                                  index as number,
                                  "value"
                                );
                              }}
                              options={
                                funcForLanguageField &&
                                funcForLanguageField(item)
                              }
                            />
                          }
                          {true && (
                            <>
                              <Select
                                key={`languageDocument-${index}`}
                                styles={{
                                  control: (base: any) => ({
                                    ...base,
                                    height: 30,
                                    minHeight: 30,
                                    backgroundColor: `${
                                      item?.language ? "#ffffff" : "#ffff89"
                                    }`,
                                  }),
                                }}
                                className="react-select-field my-2"
                                theme={themeSelect}
                                placeholder={"Language"}
                                onChange={(e) => {
                                  handleSelectMapping(
                                    e as any,
                                    index as number,
                                    "language"
                                  );
                                }}
                                defaultValue={
                                  item?.language && {
                                    value: item?.language,
                                    label: getCorrectLanguage(
                                      item?.language,
                                      t
                                    ),
                                  }
                                }
                                options={(translationLanguageEnum as any)?.map(
                                  (language: string) => ({
                                    value: language,
                                    // label: getCorrectLanguage(language, t),
                                    label: getCorrectLanguage(language, t),
                                  })
                                )}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        item?.mapTo !== null &&
                        languageVisible(item?.mapTo) && (
                          <Select
                            key={`language-${index}`}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: 30,
                                minHeight: 30,
                                backgroundColor: `${
                                  item?.language === null
                                    ? "#ffff89"
                                    : "#ffffff"
                                }`,
                              }),
                            }}
                            className="react-select-field my-2"
                            theme={themeSelect}
                            placeholder={"Language"}
                            onChange={(e) => {
                              handleSelectMapping(
                                e as any,
                                index as number,
                                "language"
                              );
                            }}
                            defaultValue={
                              item?.language && {
                                value: item?.language,
                                label: getCorrectLanguage(item?.language, t),
                              }
                            }
                            options={translationLanguageEnum?.map(
                              (language: string) => ({
                                value: language,
                                label: getCorrectLanguage(language, t),
                              })
                            )}
                            // options={(
                            //   funcForLanguageField && funcForLanguageField(item)
                            // )?.map((language: string) => ({
                            //   value: language,
                            //   label: getCorrectLanguage(language, t),
                            // }))}
                          />
                        )
                      )}
                    </div>
                  ))}
                </Row>
              ) : (
                <EmptyState />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ConfirmDeleteColumn
        show={showDelete}
        setShow={setShowDelete}
        columnId={columnId}
        itemName={(selectedColumn as any)?.name}
        deleteHandler={deleteHandlerColumn}
        selectedProduct={setSelectedColumn}
      />
    </>
  );
};
