import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IUser } from "../../../model/authentification/IRegister";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ProductsListView } from "../../products/components/ProductsListView";
import { IProductsList } from "../../products/dto/IProducts";
import { ProductsService } from "../../products/service";
import { WorkflowService } from "../service";
import { WorkflowStepper } from "./WorkflowStepper";
import { IWorkflow } from "../dto/IWorkflow";
import Select from "react-select";
import { IAllStates } from "../../../store/rootReducer";
import step5PaginationSlice from "../../../store/workflow/step5PaginationSlice";
import { GoogleSearchPromptService } from "../../googleSearchPrompt/service";

interface IProgressData {
  done: number;
  total: number;
  percent: number;
}

interface IProgressBar {
  assigment: IProgressData;
  copywriting: IProgressData;
}

export interface ICopywriterData {
  products: number[];
  copyWriter: number | null;
}

const progressArr = ["assigment", "copywriting"];

export const WorkflowStep5 = () => {
  const lightOrDarkMode = useSelector(
    (state: IAllStates) => state.lightOrDarkMode
  );
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const mainTable = true;
  const { id } = useParams();
  const stepPagination = useSelector(
    (state: IAllStates) => state.step5Pagination as any
  );
  const [pagination, setPagination] = useState({
    ...(stepPagination as unknown as any),
    // page: currentPage,
    // perPage: 10,
  });
  const [googleSearchList, setGoogleSearchList] = useState<any[]>([]);
  const [googleSearchPrompt, setGoogleSearchPrompt] = useState<number>(0);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const [productsList, setProductsList] = useState<any[]>([]);
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const [isCopyWriter, setIsCopyWriter] = useState(true);
  const [updateList, setUpdateList] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const [copywriterList, setCopywriterList] = useState<IUser[]>([]);
  const [currentWorkflow, setCurrentWorkflow] = useState<IWorkflow | null>(
    null
  );
  const [progressBarData, setProgressBarData] = useState<
    IProgressBar | undefined
  >(undefined);
  const currentPage = Number(
    +(search.split("=").at(-1) ?? 0) !== 0 ? search.split("=").at(-1) : 1
  );
  const [copywriterData, setCopywriterData] = useState<ICopywriterData>({
    products: [],
    copyWriter: null,
  });
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();
  const changeFinishHandler = async (finishedOrUnfinished: boolean) => {
    id &&
      (await WorkflowService.changeFinishedStatusCopyWrite(Number(id), {
        isDone: finishedOrUnfinished,
        products: checkedValues,
      })
        .then((response) => {
          if (response) {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_updated_status"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  const handlePages = (updatePage: number) => {
    // navigate(`?page=${updatePage}`);
    setPagination({ ...pagination, page: updatePage });
    dispatch(
      step5PaginationSlice.actions.setStep5Pagination({
        ...(stepPagination as any),
        page: updatePage,
      })
    );
    setPage(updatePage);
  };

  const selectCopywriterHandler = (event: any) => {
    setCopywriterData((prev) => ({ ...prev, copyWriter: +event?.value }));
  };
  const submitFillWorkflow = (e: any) => {
    e.preventDefault();
    GoogleSearchPromptService.fillProductWorkflow({
      workflowId: Number(id),
      promptId: googleSearchPrompt,
      titleLanguage: currentWorkflow?.sourceLang,
    })
      .then((response: any) => {
        SuccessToast(`${response?.message}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const handlePerPage = (e: any) => {
    setPagination((prev: any) => ({ ...prev, page: 1, perPage: e.value }));
    dispatch(
      step5PaginationSlice.actions.setStep5Pagination({
        ...(stepPagination as any),
        page: 1,
        perPage: e.value,
      })
    );
  };

  const viewClickHandler = (id: number) => {
    navigate(`/app/products/view/base-data/${id}`);
  };
  const toForLinkFunc = (productId: number) => {
    return `/app/workflow/step5/${productId}/${id}`;
  };
  const editClickHandler = (e: any, productId: number) => {
    if (e.type === "click") {
      navigate(`/app/workflow/step5/${productId}/${id}`);
      setSelectedProductId(productId);
    } else if (e.type === "contextmenu") {
      window.open(`/app/workflow/step5/${productId}/${id}`, "_blank");
      setSelectedProductId(productId);
    }
  };

  const changeFilterHandler = (name: string, value: string) => {
    if ((pagination as any)?.[name] === value) {
      setPagination((prev: any) => ({ ...prev, [name]: "" }));
      dispatch(
        step5PaginationSlice.actions.setStep5Pagination({
          ...(stepPagination as any),
          [name]: "",
        })
      );
    } else {
      setPagination((prev: any) => ({ ...prev, [name]: value }));
      dispatch(
        step5PaginationSlice.actions.setStep5Pagination({
          ...(stepPagination as any),
          [name]: value,
        })
      );
    }
  };
  const selectGoogleSearchHandler = (e: any) => {
    setGoogleSearchPrompt(+e.value);
  };
  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues((prev) => [...prev, +e.target.value]);
    } else {
      setCheckedValues((prev) =>
        prev.filter((value) => value !== +e.target.value)
      );
    }
  };

  const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues(productsList?.map((product) => product.id));
    } else {
      setCheckedValues([]);
    }
  };
  const submitGoogleSearch = (e: any) => {
    e.preventDefault();
    GoogleSearchPromptService.enrichProductWorkflow({
      workflowId: Number(id),
      promptId: googleSearchPrompt,
      titleLanguage: currentWorkflow?.sourceLang,
    })
      .then((response: any) => {
        SuccessToast(`${response?.message}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const assignCopywriterHandler = async () => {
    id &&
      (await WorkflowService.assignCopywriter(Number(id), {
        ...copywriterData,
        products: checkedValues,
      })
        .then((response) => {
          if (response === "") {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_assigned"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  const generateVariationsAi = (e: any) => {
    e.preventDefault();
    WorkflowService.generateAiVariations(Number(id))
      .then((response: any) => {
        SuccessToast(response?.message);
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    GoogleSearchPromptService.getAllPrompts({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setGoogleSearchList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const deleteHandler = (product: IProductsList) => {
    product?.id &&
      ProductsService.deleteProductById(product?.id)
        .then((response) => {
          setPagination((prev: any) => ({ ...prev, page: 1 }));
          dispatch(
            step5PaginationSlice.actions.setStep5Pagination({
              ...(stepPagination as any),
              page: 1,
            })
          );
          SuccessToast(`${t("toasts.successfully_deleted")} ${product?.title}`);
        })
        .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    WorkflowService.getCopywritingProducts(pagination, +id!)
      .then((response) => {
        const {
          list,
          view,
          workflow,
          workflow: { copywriters },
          assigment,
          copywriting,
          workers,
        } = response as any;

        setProductsList(list?.data);
        setCurrentWorkflow(workflow as IWorkflow);
        setProgressBarData({
          assigment,
          copywriting,
        });
        window.scrollTo(0, 0);
        setIsCopyWriter(+view !== 1);
        setCopywriterList(workers);
        setTotalPages(Math.ceil(list?.count / list?.perPage));
        setPage(list?.page);
        localStorage.setItem("workflowLang", "");
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, currentPage, updateList, selectedProductId]);
  return (
    <Card>
      <Card.Body>
        <WorkflowStepper
          stepPagination={stepPagination}
          step={"COPY_WRITING"}
          id={id}
          changeFilterHandler={changeFilterHandler}
          precent={progressBarData?.["copywriting"]?.percent}
          showNextButton={isCopyWriter}
          showMarkAsComplete={
            currentWorkflow?.status === "COPY_WRITING" &&
            (isAdmin || isCompanyAdmin || isManager)
          }
          status={pagination?.status}
          assigned={!isCopyWriter && pagination?.assigned}
          setSelectedProductId={!selectedProductId && setSelectedProductId}
          handlePerPage={handlePerPage}
        />

        {progressArr?.map((data) => {
          if (data === "assigment" && isCopyWriter) {
            return null;
          }
          return (
            <div
              className="card bg-light overflow-hidden shadow-none"
              key={data}
            >
              <div className="card-body">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h6 className="mb-0">
                      {t("workflow.current")} {data}:
                      <b className="text-success">
                        {" "}
                        {progressBarData?.[data as keyof IProgressBar]?.percent}
                        %
                      </b>
                    </h6>
                  </div>
                  <div className="flex-shrink-0">
                    <h6 className="mb-0">
                      {progressBarData?.[data as keyof IProgressBar]?.done}/
                      {progressBarData?.[data as keyof IProgressBar]?.total}{" "}
                      {t("workflow.finished")}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="progress">
                {/*//@ts-ignore*/}
                <div
                  className="progress-bar bg-success"
                  style={{
                    width: `${
                      progressBarData?.[data as keyof IProgressBar]?.percent ??
                      0
                    }%`,
                  }}
                  aria-valuenow={
                    progressBarData?.[data as keyof IProgressBar]
                      ?.percent as number
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          );
        })}
        <Col xs={12}>
          <div className="table-responsive">
            {!isCopyWriter && (
              <div className="d-flex justify-content-between align-items-center mb-1">
                <div className="d-flex align-items-center gap-2">
                  <h6 className="m-0 p-0 ">{t("workflow.copywriter")}:</h6>
                  {lightOrDarkMode === "dark" ? (
                    <Select
                      className="react-select-field"
                      styles={{
                        option: (base, state) => ({
                          ...base,
                          backgroundColor: state.isSelected
                            ? "#007aff"
                            : "#262a2f",
                          ":hover": {
                            backgroundColor: state.isSelected
                              ? "#007aff"
                              : "black",
                          },
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 250,
                          zIndex: 9999,
                          ":hover": {
                            backgroundColor: "black",
                          },
                        }),

                        menu: (base) => ({
                          ...base,
                          backgroundColor: "#262a2f",
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      onChange={selectCopywriterHandler}
                      isClearable={true}
                      options={copywriterList?.map((copyWriter: any) => ({
                        label: `${copyWriter?.firstName} ${
                          copyWriter?.lastName
                        } (${copyWriter?.assignment}  ${t(
                          "products.products"
                        ).toLowerCase()})`,
                        value: copyWriter.id,
                      }))}
                    />
                  ) : (
                    <Select
                      className="react-select-field"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 250,
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      onChange={selectCopywriterHandler}
                      isClearable={true}
                      options={copywriterList?.map((copyWriter: any) => ({
                        label: `${copyWriter?.firstName} ${
                          copyWriter?.lastName
                        } (${copyWriter?.assignment}  ${t(
                          "products.products"
                        ).toLowerCase()})`,
                        value: copyWriter.id,
                      }))}
                    />
                  )}

                  <button
                    onClick={assignCopywriterHandler}
                    className="btn  btn-secondary d-flex align-items-center me-2"
                    disabled={
                      !copywriterData?.copyWriter || !checkedValues?.length
                    }
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.assign")}
                  </button>
                  <button
                    onClick={() => changeFinishHandler(true)}
                    className="btn  btn-success d-flex align-items-center me-2"
                    disabled={
                      (copywriterData as any)?.copyWriter ||
                      !checkedValues?.length
                    }
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.finished")}
                  </button>
                  <button
                    onClick={() => changeFinishHandler(false)}
                    className="btn  btn-danger d-flex align-items-center me-2"
                    disabled={
                      (copywriterData as any)?.copyWriter ||
                      !checkedValues?.length
                    }
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.unfinished")}
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={(e: any) => generateVariationsAi(e)}
                  >
                    <span> Generate variations</span>
                  </button>
                </div>
                <div className="d-flex">
                  {accessRights?.includes(
                    "data_enrichment_workflow_all_products"
                  ) && (
                    <>
                      <Select
                        className="react-select-field w-240"
                        placeholder={"Google search prompt"}
                        onChange={selectGoogleSearchHandler}
                        // isClearable={true}
                        options={googleSearchList.map((exportItem) => ({
                          label: exportItem.title,
                          value: exportItem.id,
                        }))}
                      />
                      <button
                        style={{ minWidth: "141px" }}
                        className="btn btn-success ms-1 me-2"
                        disabled={googleSearchPrompt === 0}
                        onClick={(e: any) => submitGoogleSearch(e)}
                      >
                        <span> {t("data_enrichment.enrichment")}</span>
                      </button>
                      <button
                        // style={{ minWidth: "141px" }}
                        className="btn btn-info ms-1 me-2"
                        // disabled={googleSearchPrompt === 0}
                        onClick={(e: any) => submitFillWorkflow(e)}
                      >
                        {/* {isLoading ? (
                    <Spinner animation="border" size="sm" role="status" />
                  ) : ( */}
                        <span> {t("global.fill")}</span>
                        {/* )} */}
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
            {!!productsList?.length ? (
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    {!isCopyWriter && (
                      <th scope="col">
                        <input
                          className="form-check-input m-0 cursor-pointer"
                          checked={
                            checkedValues.length === productsList?.length
                          }
                          type="checkbox"
                          onChange={checkAll}
                        />
                      </th>
                    )}
                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("global.aenCode")}`}</th>
                    <th scope="col">{`${t("global.finished")}`}</th>
                    {!isCopyWriter && (
                      <th scope="col">{`${t("global.assign")}`}</th>
                    )}
                    <th scope="col">{`${t("products.product")}`}</th>
                    <th scope="col">{`${t("products.brand")}`}</th>
                    <th scope="col">{`${t("products.category")}`}</th>
                    <th scope="col">{`${t("products.createdAt")}`}</th>
                    <th scope="col">{`${t("global.actions")}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {productsList?.map((product) => (
                    <ProductsListView
                      toForLinkFunc={toForLinkFunc}
                      workflow={true}
                      nameOfTable={
                        !isCopyWriter ? "content" : "contentWorkflow"
                      }
                      key={product?.id}
                      showViewButton={false}
                      showAssignColumn={!isCopyWriter}
                      showDeleteButton={false}
                      showActionButtons={isCopyWriter}
                      onEdit={editClickHandler}
                      checkedValues={checkedValues}
                      handleCheck={checkboxHandler}
                      onView={viewClickHandler}
                      product={product}
                      mainTable={mainTable}
                      deleteHandler={deleteHandler}
                    />
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyState />
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-end ">
                {totalPages <= 1 ? (
                  ""
                ) : (
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        {/* )} */}
      </Card.Body>
    </Card>
  );
};
