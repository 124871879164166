import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../../../shared/components/EmptyState";

export const WorkflowStep7List = ({ stats }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex justify-content-start">
                <h4 className="card-title mg-b-0">
                  {t("workflow.copywriters")}
                </h4>
              </div>
            </div>
            <div className="card-body">
              {stats?.copywritersData?.length === 0 ? (
                <EmptyState />
              ) : (
                <div className="table-responsive">
                  <Table className="align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">{`${t("global.name")}`}</th>
                        <th scope="col">{`${t("global.inprogress")}`}</th>
                        <th scope="col">{`${t("global.done")}`}</th>
                        <th scope="col">{`${t("global.total")}`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stats?.copywritersData?.map((user: any) => {
                        return (
                          <tr>
                            <td>{user.firstName + " " + user.lastName}</td>
                            <td>{user.isNotDone}</td>
                            <td>{user.isDone}</td>
                            <td>{user.total}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex justify-content-start">
                <h4 className="card-title mg-b-0">
                  {t("workflow.translators")}
                </h4>
              </div>
            </div>
            <div className="card-body">
              {stats?.translatorsData?.length === 0 ? (
                <EmptyState />
              ) : (
                <div className="table-responsive">
                  <Table className="align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">{`${t("global.name")}`}</th>
                        <th scope="col">{`${t("global.inprogress")}`}</th>
                        <th scope="col">{`${t("global.done")}`}</th>
                        <th scope="col">{`${t("global.total")}`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stats?.translatorsData?.map((user: any) => {
                        return (
                          <tr>
                            <td>{user.firstName + " " + user.lastName}</td>
                            <td>{user.isNotDone}</td>
                            <td>{user.isDone}</td>
                            <td>{user.total}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
