// @ts-nocheck
import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentEditableEvent, DefaultEditor } from "react-simple-wysiwyg";
import Select, { SingleValue } from "react-select";
import { Button, Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import i18n from "../../../utils/translations/i18next";
import { IAllStates } from "../../../store/rootReducer";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ProductsService } from "../../products/service";
import { PageTemplate } from "../../../shared/layout/PageTemplate";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { Pagination } from "../../../shared/paginations/Paginations";
import { themeSelect } from "../../../shared/components/Select2";
import { ContentService } from "../service";
import { useDispatch, useSelector } from "react-redux";
import {
  IContent,
  IContentEdit,
  IContentVariationList,
  IContentVariationParams,
} from "../dto/IContent";
// import { Editor } from "@tinymce/tinymce-react";
import { ContentVariationForm } from "./ContentVarationForm";
import { DisabledContentEditor } from "./DisabledContentEditor";
import { getCorrectFormality } from "../../../shared/functions/Functions";
import { ContentVariation } from "./ContentVariation";
import { ContentUpdateVariation } from "./ContentUpdateVariation";
import { ChatGptContent } from "../../products/content/components/ChatGptContent";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// eslint-disable-next-line @typescript-eslint/no-redeclare
import Editor from "ckeditor5-custom-build/build/ckeditor";
import _debounce from "lodash/debounce";
import contentFirstViewSlice from "../../../store/category/contentFirstView";
import "../../../ckeditor.css";
import { ClientService } from "../../clients/service";
import { GlossaryService } from "../../glossary/service";

const variationInitial = {
  content: 0,
  text: "",
};

declare module "ckeditor5-custom-build/build/ckeditor" {
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import Event from "@ckeditor/ckeditor5-utils/src/eventinfo";
  import { EditorConfig } from "@ckeditor/ckeditor5-core/src/editor/editorconfig";
  import * as React from "react";
  const CKEditor: React.FunctionComponent<{
    disabled?: boolean;
    editor: typeof ClassicEditor;
    data?: string;
    id?: string;
    config?: EditorConfig;
    onReady?: (editor: ClassicEditor) => void;
    onChange?: (event: Event, editor: ClassicEditor) => void;
    onBlur?: (event: Event, editor: ClassicEditor) => void;
    onFocus?: (event: Event, editor: ClassicEditor) => void;
    onError?: (event: Event, editor: ClassicEditor) => void;
  }>;
  export { CKEditor };
}

interface ICounts {
  words: number;
  characters: number;
}
interface IEditorCounter {
  [key: string]: ICounts;
}
interface IMessage {
  content: string;
  role: "assistant" | "user";
  sent?: Date;
}
const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      "|",
      "glossary-scan",
      // "glossary-ignore",
      "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};
export const EditContent = () => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const dispatch = useDispatch();
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [currentContent, setCurrentContent] = useState({} as IContent);
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [formality, setFormality] = useState(null);
  const [refreshState, setRefreshState] = useState(false);
  let titleTranslate = "";
  let textTranslate = "";
  const [currentVariation, setCurrentVariation] = useState(
    {} as IContentVariationParams
  );
  const [variationList, setVariationList] = useState<IContentVariationParams[]>(
    []
  );
  const [currentVariationTranslation, setCurrentVariationTranslation] =
    useState<IContentVariationParams>();
  const { id } = useParams();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const contentFirstView = useSelector(
    (state: IAllStates) => state.contentFirstView
  );
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { t } = useTranslation();
  const currentLanguage = i18n?.language;
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [triggerContentUpdate, setTriggerContentUpdate] = useState(false);
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("language") ?? "de"
  );
  const [currentContentDisabled, setCurrentContentDisabled] = useState();
  const [activeTabDisabled, setActiveTabDisabled] = useState(
    localStorage.getItem("language") ?? "de"
  );
  const navigate = useNavigate();
  const [showVariationModal, setShowVariationModal] = useState(false);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  const changeActiveTabDisabled = (tab: string) => {
    setActiveTabDisabled(tab);
  };
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const handleClose = () => {
    setShowVariationModal(false);
    setCurrentVariation(variationInitial);
    setTriggerUpdate((prev) => !prev);
  };
  const goBack = () => {
    navigate(-1);
  };
  const handleShow = () => {
    setShowVariationModal(true);
  };
  const handleSubmit = (
    event: React.FormEvent,
    language: string,
    localContent: any
  ) => {
    if (id) {
      ContentService.updateContent(
        {
          lang: language,
          text: localContent?.translation?.[language]?.text,
          title: localContent?.translation?.[language]?.title,
        } as IContentEdit,
        +id
      )
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_updated")}`);
          setCurrentContent(response);
          setTriggerContentUpdate((prev) => !prev);
          setMessages([]);
          navigate(1);
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >,
    language: string,
    localEditor: any
  ) => {
    // @ts-ignore
    const { value, name } = event.target;
    localEditor((prev: IContent) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [language]: {
          ...prev?.translation?.[language],
          [name]: value,
        },
      },
    }));
  };
  const changeHandlerEditor = (
    value: string,
    language: string,
    eventName: string
  ) => {
    // @ts-ignore
    setCurrentContent((prev: IContent) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [language]: {
          ...prev?.translation?.[language],
          [eventName]: value,
        },
      },
    }));
  };
  const changeView1 = () => {
    dispatch(contentFirstViewSlice.actions.setContentFirstView(true));
  };
  const changeView2 = () => {
    dispatch(contentFirstViewSlice.actions.setContentFirstView(false));
  };

  const handleSaveVariation = () => {
    ContentService.createVariation({
      lang: currentLanguage,
      content: currentContent?.id,
      title: currentVariation?.title,
      text: currentVariation?.text,
    })
      .then((response) => {
        if (response?.id) {
          SuccessToast(
            `${t("toasts.successfully_created")} ${
              response?.translation?.en?.title
            }`
          );
          setCurrentVariation(variationInitial as any);
          setTriggerUpdate((prev) => !prev);
          handleClose();
        }
      })
      .catch((error) => ErrorToast(error));
  };
  // function handleSelectSingle(
  //   selectedSingle: SingleValue<{ label: string; value: string }>,
  //   language: string
  // ) {

  //   console.log(selectedSingle, language, 'bojan');

  //   setSourceLanguage(selectedSingle?.value);
  //   setTargetLanguage(language as string);
  // }
  const handleTranslate = (
    formality,
    sourceLanguage,
    targetLanguage,
    localContent: any
  ) => {
    //
    ContentService.postTranslation({
      text: localContent?.translation?.[sourceLanguage]?.text,
      title: localContent?.translation?.[sourceLanguage]?.title,
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response: any) => {
        if (
          localContent?.translation?.[targetLanguage]?.text === "" ||
          localContent?.translation?.[targetLanguage]?.text === undefined ||
          localContent?.translation?.[targetLanguage]?.text === null
        ) {
          textTranslate = response[1];
        } else {
          textTranslate = localContent?.translation?.[
            targetLanguage
          ]?.text.concat(" ", response[1]);
        }
        if (
          localContent?.translation?.[targetLanguage]?.title === "" ||
          localContent?.translation?.[targetLanguage]?.title === undefined ||
          localContent?.translation?.[targetLanguage]?.title === null
        ) {
          titleTranslate = response[0];
        } else {
          titleTranslate = localContent?.translation?.[
            targetLanguage
          ]?.title.concat(" ", response[0]);
        }
        setCurrentContent((prev: any) => ({
          ...prev,
          translation: {
            ...prev?.translation,
            [targetLanguage]: {
              ...prev?.translation?.[targetLanguage],
              title: titleTranslate,
              text: textTranslate,
            },
          },
        }));
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const clientGlossary = () => {
    if (currentContent && activeTab) {
      GlossaryService.getAllGlossary({
        client: currentContent?.client?.id as number,
        language: activeTab,
        page: 1,
        perPage: 0,
      })
        .then((response) => {
          const { data } = response;
          const glossaries = data.map((glossary) => {
            return {
              [glossary.word]: glossary.alternative,
            };
          });
          const glossaryObject = glossaries.reduce((result, currentObject) => {
            return Object.assign(result, currentObject);
          }, {});
          const glossaryClientObject = { client: currentContent?.client?.name };

          const objectStorage = JSON.stringify(glossaryObject);
          const objectClient = JSON.stringify(glossaryClientObject);

          localStorage.setItem("glossary", objectStorage);
          localStorage.setItem("glossaryClient", objectClient);
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    id &&
      ContentService.getContent(Number(id))
        .then((response) => {
          setCurrentContent(response);
        })
        .catch((error) => ErrorToast(error));
  }, [id]);
  useEffect(() => {
    if (id && activeTab) {
      clientGlossary();
    }
  }, [currentContent, activeTab]);
  useEffect(() => {
    id &&
      ContentService.getAllVariations({
        content: Number(id),
        ...Pagination,
      } as IContentVariationList)
        .then((response) => {
          setVariationList(response?.data);
        })
        .catch((error) => ErrorToast(error));
  }, [id, triggerUpdate]);
  useEffect(() => {
    if (currentLanguage) {
      setActiveTab("en");
    }
  }, [currentLanguage]);
  const EditorWithContent = useCallback(() => {
    return (
      <EditorTabs
        currentContent={currentContent}
        translationLanguageEnum={translationLanguageEnum}
        activeTab={activeTab}
        handleTranslate={handleTranslate}
        // isLoading={isLoading}
        id={id}
        changeHandlerEditor={changeHandlerEditor}
        handleSubmit={handleSubmit}
        changeHandler={changeHandler}
        // handleSelectSingle={handleSelectSingle}
        sourceLanguage={sourceLanguage}
        targetLanguage={targetLanguage}
        setTriggerUpdate={setTriggerUpdate}
        deeplFormality={deeplFormality}
        // handleSelectSingleFormality={handleSelectSingleFormality}
        getCorrectFormality={getCorrectFormality}
      />
    );
  }, [
    currentContent,
    activeTab,
    translationLanguageEnum,
    // sourceLanguage,
    targetLanguage,
    // setTriggerUpdate,
  ]);
  const EditorWithContentDisabled = useCallback(() => {
    return (
      <EditorTabsDisabled
        //   contentCopywrite={contentCopywrite}
        alwaysDisabled={true}
        currentContent={currentContent}
        setCurrentContent={setCurrentContent}
        translationLanguageEnum={translationLanguageEnum}
        activeTabDisabled={activeTabDisabled}
        handleTranslate={handleTranslate}
        // isLoading={isLoading}
        id={id}
        changeHandlerEditor={changeHandlerEditor}
        handleSubmit={handleSubmit}
        changeHandler={changeHandler}
        // handleSelectSingle={handleSelectSingle}
        sourceLanguage={sourceLanguage}
        targetLanguage={targetLanguage}
        setTriggerUpdate={setTriggerUpdate}
        goBack={goBack}
        deeplFormality={deeplFormality}
        // handleSelectSingleFormality={handleSelectSingleFormality}
        getCorrectFormality={getCorrectFormality}
      />
    );
  }, [
    currentContent,
    setCurrentContent,
    activeTabDisabled,
    translationLanguageEnum,
    // sourceLanguage,
    targetLanguage,
    // setTriggerUpdate,
    // contentCopywrite,
  ]);
  return (
    <div className="col-12">
      <div className="col-md-12 mb-3 d-flex align-items-center justify-content-between">
        <div>
          <button
            onClick={goBack}
            className="btn btn-info d-flex align-items-center"
          >
            <i className="ri-arrow-left-line"></i>
            {t("products.back")}
          </button>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-primary d-flex align-items-center me-2"
            data-bs-toggle="offcanvas"
            data-bs-target={`#offcanvasRight-${id}`}
            aria-controls={`offcanvasRight-${id}`}
          >
            <i className="ri-message-3-line me-1" />
            {t("products.chatGpt")}
          </button>
          <button
            onClick={handleShow}
            className="btn btn-success d-flex align-items-center"
          >
            <i className="ri-play-list-add-line me-1" />
            {t("products.createVariation")}
          </button>
        </div>
      </div>
      <>
        <div
          className="offcanvas offcanvas-end main-height w-50"
          id={`offcanvasRight-${id}`}
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header">
            <h5 id="offcanvasRightLabel">{t("products.chatGpt")}</h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ChatGptContent messages={messages} setMessages={setMessages} />
          </div>
        </div>
      </>
      <div className="row">
        <div className="col-12">
          <div className="d-flex w-100 align-items-center justify-content-end mb-3">
            <button
              className={`btn btn${
                contentFirstView !== true ? "-soft" : ""
              }-info  btn-sm`}
              onClick={() => changeView1()}
            >
              <i className="ri-list-check " />
            </button>
            <button
              className={`btn btn${
                contentFirstView === true ? "-soft" : ""
              }-info btn-sm ms-2`}
              onClick={() => changeView2()}
            >
              <i className="ri-bank-card-line" />
            </button>
          </div>
        </div>
      </div>
      {contentFirstView ? (
        <div className="row">
          <div className="col-12 col-xl-9">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  {translationLanguageEnum?.map((language) => {
                    return (
                      <li
                        className="nav-item"
                        key={language}
                        onClick={() => changeActiveTab(language)}
                      >
                        <a
                          className={`nav-link ${
                            activeTab === language ? "active" : ""
                          }`}
                          data-bs-toggle="tab"
                          href={`#${language}`}
                          role="tab"
                          aria-selected={language === currentLanguage}
                        >
                          {t(`products.content_${language}`)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <EditorWithContent />
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-3">
            {variationList?.map((variation) => (
              <ContentVariation
                setCurrentVariationTranslation={setCurrentVariationTranslation}
                setCurrentVariation={setCurrentVariation}
                setVariationList={setVariationList}
                handleShow={handleShow}
                variation={variation}
                key={variation?.id}
              />
            ))}
            <div className="col-12 d-flex justify-content-end ">
              {totalPages <= 1 ? (
                ""
              ) : (
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  {translationLanguageEnum?.map((language) => {
                    return (
                      <li
                        className="nav-item"
                        key={language}
                        onClick={() => changeActiveTabDisabled(language)}
                      >
                        <a
                          className={`nav-link ${
                            activeTabDisabled === language ? "active" : ""
                          }`}
                          data-bs-toggle="tab"
                          href={`#${language}`}
                          role="tab"
                          aria-selected={language === currentLanguage}
                        >
                          {t(`products.content_${language}`)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <EditorWithContentDisabled />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  {translationLanguageEnum?.map((language) => {
                    return (
                      <li
                        className="nav-item"
                        key={language}
                        onClick={() => changeActiveTab(language)}
                      >
                        <a
                          className={`nav-link ${
                            activeTab === language ? "active" : ""
                          }`}
                          data-bs-toggle="tab"
                          href={`#${language}`}
                          role="tab"
                          aria-selected={language === currentLanguage}
                        >
                          {t(`products.content_${language}`)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <EditorWithContent />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {variationList?.map((variation) => (
              <ContentVariation
                setCurrentVariationTranslation={setCurrentVariationTranslation}
                setCurrentVariation={setCurrentVariation}
                setVariationList={setVariationList}
                handleShow={handleShow}
                variation={variation}
                key={variation?.id}
              />
            ))}
            <div className="col-12 d-flex justify-content-end ">
              {totalPages <= 1 ? (
                ""
              ) : (
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <>
        {!currentVariation?.id ? (
          <Modal
            show={showVariationModal}
            onHide={handleClose}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {t("products.variation.createVariation")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ContentVariationForm
                currentVariation={currentVariation}
                setCurrentVariation={setCurrentVariation}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="info" onClick={handleClose}>
                {t("global.cancel")}
              </Button>
              <Button variant="primary" onClick={handleSaveVariation}>
                {t("category.save")}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <ContentUpdateVariation
            currentVariation={currentVariationTranslation}
            variationId={currentVariation?.id}
            setCurrentVariation={setCurrentVariationTranslation}
            showVariationModal={showVariationModal}
            handleClose={handleClose}
            setTriggerUpdate={setTriggerUpdate}
          />
        )}
      </>
    </div>
    // </PageTemplate>
  );
};

const EditorTabs = ({
  currentContent,
  handleTranslate,
  translationLanguageEnum,
  activeTab,
  id,
  changeHandlerEditor,
  fieldName = "text",
  handleSubmit,
  changeHandler,
  // handleSelectSingle,
  // sourceLanguage,
  // targetLanguage,
  setTriggerUpdate,
  deeplFormality,
  getCorrectFormality,
  // handleSelectSingleFormality,
}) => {
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [formality, setFormality] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clientList, setClientList] = useState<IExport[]>([]);
  const [localContent, localEditor] = useState(currentContent);
  const [clientId, setClientId] = useState(null);
  const [wordCount, setWordCount] = useState<IEditorCounter>();
  const { t } = useTranslation();
  const clientName = JSON.parse(localStorage.getItem("glossaryClient"));
  const navigate = useNavigate();
  const updateWordsCound = _debounce(
    ({ wordsCount, charactersCount, language }) => {
      setWordCount((prev) => ({
        ...prev,
        [language]: {
          words: wordsCount,
          characters: charactersCount,
        },
      }));
    },
    500
  );
  const handleAiVariation = () => {
    setIsLoading(true);
    id &&
      ContentService.addNewAiVaraiton(+id)
        .then((response) => {
          setTriggerUpdate(true);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error))
        .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    ClientService.getAllClients({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>,
    language: string
  ) {
    setSourceLanguage(selectedSingle?.value);
    setTargetLanguage(language as string);
  }
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  return (
    <div className="tab-content">
      {translationLanguageEnum?.map((language) => {
        return (
          <div
            className={`tab-pane  ${activeTab === language ? "active" : ""}`}
            key={language}
            id={language}
            role="tabpanel"
          >
            <div className="row mt-4">
              <div className="d-flex justify-content-end align-items-center my-1">
                <div className="d-flex">
                  <Select
                    className="custom_zindex react-select-field me-2"
                    name="content"
                    theme={themeSelect}
                    placeholder={`${t("formality.select_formality")}`}
                    options={deeplFormality?.map((formality) => ({
                      value: formality,
                      label: getCorrectFormality(formality, t),
                    }))}
                    // key={state?.id}
                    onChange={(e) => {
                      handleSelectSingleFormality(e as any);
                    }}
                  />
                  <Select
                    className="custom_zindex react-select-field me-2"
                    theme={themeSelect}
                    placeholder="Translate from"
                    isClearable={true}
                    options={translationLanguageEnum
                      .filter((lang) => lang !== language)
                      ?.map((lang) => ({
                        value: lang,
                        label: getCorrectLanguage(lang, t),
                      }))}
                    onChange={(e) => {
                      handleSelectSingle(e, language);
                    }}
                  />
                  <button
                    className="btn btn-secondary"
                    onClick={(e) =>
                      handleTranslate(
                        formality,
                        sourceLanguage,
                        targetLanguage,
                        localContent
                      )
                    }
                  >
                    {t("global.translate")}
                  </button>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <label className="m-0 required-field" htmlFor="content">
                  {t("global.title")}
                </label>
                <div className="d-flex justify-content-between align-items-center my-1">
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      value={localContent?.translation?.[language]?.title ?? ""}
                      required
                      onChange={(e) => changeHandler(e, language, localEditor)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <label className="m-0" htmlFor="content">
                  {t("side_bar.glossary")}
                </label>
                <div className="d-flex justify-content-between align-items-center my-1">
                  <div className="col-md-10">
                    <Select
                      isDisabled={true}
                      className="react-select-field"
                      placeholder={"Glossary client"}
                      theme={themeSelect}
                      // key={state?.id}
                      defaultValue={
                        localStorage.getItem("glossaryClient") && {
                          // label: clientName?.client as string,
                          label: localContent?.client?.name as string,
                        }
                      }
                      // onChange={(e) => {
                      //   handleSelectSingleClient(e as any, language);
                      // }}
                      // isMulti={true}
                      options={(clientList as any)?.map((client: any) => ({
                        value: client?.id,
                        label: client?.name,
                      }))}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center my-1">
                  <label className="m-0" htmlFor="content">
                    {t("products.content")}
                  </label>
                </div>
                {activeTab === language && (
                  <CKEditor
                    className="ckeditor-custom"
                    config={editorConfigurationCustom}
                    editor={Editor}
                    data={localContent?.translation?.[language]?.text ?? ""}
                    onReady={(editor) => {
                      editor.ui.view.element.setAttribute(
                        "id",
                        String(Math.random().toString(16).slice(2))
                      );
                      const wordsCount = editor.plugins.get("WordCount").words;
                      const charactersCount =
                        editor.plugins.get("WordCount").characters;
                      setWordCount((prev) => ({
                        ...prev,
                        [language]: {
                          words: wordsCount,
                          characters: charactersCount,
                        },
                      }));
                      //   });
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // localEditor(data, language as string, "text");
                      localEditor((prev: IContent) => ({
                        ...prev,
                        translation: {
                          ...prev?.translation,
                          [language]: {
                            ...prev?.translation?.[language],
                            [fieldName]: data,
                          },
                        },
                      }));
                      const wordsCount = editor.plugins.get("WordCount").words;
                      const charactersCount =
                        editor.plugins.get("WordCount").characters;
                      updateWordsCound({
                        wordsCount,
                        charactersCount,
                        language,
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-between text-align-center mt-3">
                <div className="ck ck-word-count">
                  <div className="ck-word-count__words">
                    {t("global.words")}: {wordCount?.[language]?.words}
                  </div>
                  <div className="ck-word-count__characters">
                    {t("global.characters")}:{" "}
                    {wordCount?.[language]?.characters}
                  </div>
                </div>
                <div>
                  <button className="btn btn-info" onClick={() => navigate(-1)}>
                    {t("products.close")}
                  </button>
                  <button
                    className="btn btn-success ms-2"
                    onClick={(e) => handleAiVariation()}
                  >
                    {isLoading ? (
                      <Spinner animation="border" size="sm" role="status" />
                    ) : (
                      <span>{t("products.ai_variation")}</span>
                    )}
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    onClick={(e) => handleSubmit(e, language, localContent)}
                  >
                    {id ? `${t("products.save")}` : `${t("global.create")}`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
const EditorTabsDisabled = ({
  // contentCopywrite,
  alwaysDisabled,
  currentContent,
  setCurrentContent,
  handleTranslate,
  translationLanguageEnum,
  activeTabDisabled,
  id,
  changeHandlerEditor,
  fieldName = "text",
  handleSubmit,
  changeHandler,
  // handleSelectSingle,
  // sourceLanguage,
  // targetLanguage,
  setTriggerUpdate,
  goBack,
  deeplFormality,
  getCorrectFormality,
}) => {
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [formality, setFormality] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clientList, setClientList] = useState<IExport[]>([]);
  const [localContent, localEditor] = useState(currentContent);
  const [clientId, setClientId] = useState(null);
  const [wordCount, setWordCount] = useState<IEditorCounter>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clientName = JSON.parse(localStorage.getItem("glossaryClient"));
  const navigate = useNavigate();
  const updateWordsCound = _debounce(
    ({ wordsCount, charactersCount, lang }) => {
      setWordCount((prev) => ({
        ...prev,
        [lang]: {
          words: wordsCount,
          characters: charactersCount,
        },
      }));
    },
    500
  );
  return (
    <div className="tab-content">
      {translationLanguageEnum?.map((lang) => {
        return (
          <div
            className={`tab-pane  ${
              activeTabDisabled === lang ? "active" : ""
            }`}
            key={lang}
            id={lang}
            role="tabpanel"
          >
            <div className="row mt-4">
              <div className="col-md-6 mb-2">
                <label className="m-0 required-field" htmlFor="content">
                  {t("global.title")}
                </label>
                <div className="d-flex justify-content-between align-items-center my-1">
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="title"
                      disabled={true}
                      className="form-control"
                      value={localContent?.translation?.[lang]?.title ?? ""}
                      required
                      onChange={(e) => changeHandler(e, lang, localEditor)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center my-1">
                  <label className="m-0" htmlFor="content">
                    {t("products.content")}
                  </label>
                </div>
                {activeTabDisabled === lang && (
                  <CKEditor
                    disabled={true}
                    className="ckeditor-custom"
                    config={editorConfigurationCustom}
                    editor={Editor}
                    data={localContent?.translation?.[lang]?.text ?? ""}
                    onReady={(editor) => {
                      editor.ui.view.element.setAttribute(
                        "id",
                        String(Math.random().toString(16).slice(2))
                      );
                      const wordsCount = editor.plugins.get("WordCount").words;
                      const charactersCount =
                        editor.plugins.get("WordCount").characters;
                      setWordCount((prev) => ({
                        ...prev,
                        [lang]: {
                          words: wordsCount,
                          characters: charactersCount,
                        },
                      }));
                      //   });
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      dispatch(
                        changeLocationSlice.actions.setChangeLocation(true)
                      );
                      // localEditor(data, lang as string, "text");
                      localEditor((prev: IContent) => ({
                        ...prev,
                        translation: {
                          ...prev?.translation,
                          [lang]: {
                            ...prev?.translation?.[lang],
                            [fieldName]: data,
                          },
                        },
                      }));
                      const wordsCount = editor.plugins.get("WordCount").words;
                      const charactersCount =
                        editor.plugins.get("WordCount").characters;
                      updateWordsCound({
                        wordsCount,
                        charactersCount,
                        lang,
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-start text-align-center mt-3">
                <div className="ck ck-word-count">
                  <div className="ck-word-count__words">
                    {t("global.words")}: {wordCount?.[lang]?.words}
                  </div>
                  <div className="ck-word-count__characters">
                    {t("global.characters")}: {wordCount?.[lang]?.characters}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
