import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ClientMappingService } from "./service";
import { ErrorToast } from "../../shared/toasters/toasters";
import _debounce from "lodash/debounce";
import { ListMapping } from "./components/ListMapping";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import clientIdSlice from "../../store/clientMapping/clientId";

export const MappingClients = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [companiesList, setCompaniesList] = useState<any[] | undefined>();
  const [paginate, setPaginate] = useState({
    page: 1,
    perPage: 10,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    setPaginate({ ...paginate, page: updatePage });
    setPage(updatePage);
  };

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPaginate((prev) => ({ ...prev, [name]: value }));
    },
    200
  );

  useEffect(() => {
    ClientMappingService.getClientMappingById(Number(id), paginate)
      .then((response) => {
        const { data } = response;
        setCompaniesList(data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [JSON.stringify(paginate), triggerUpdate]);
  useEffect(() => {
    dispatch(clientIdSlice.actions.setClientId(id));
  }, [id]);
  return (
    <>
      <div className="col-xl-12 mb-3">
        <div className="card ">
          <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">{t("mapping.create_mapping")}</h4>
            <button
              className="btn btn-primary"
              onClick={() => navigate(`/app/clients/mapping-create/${id}`)}
            >
              {t("global.create")}
            </button>
          </div>
        </div>
      </div>
      <div className="col-xl-12">
        <ListMapping
          companiesList={companiesList}
          changeFilterHandler={changeFilterHandler}
          page={page}
          totalPages={totalPages}
          handlePages={handlePages}
          setTriggerUpdate={setTriggerUpdate}
        />
      </div>
    </>
  );
};
