export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_MODE
    : process.env.REACT_APP_PRO_MODE;

export const LOGIN_URL = BASE_URL + "signin";
export const REGISTER_URL = BASE_URL + "register";
export const USER_URL = BASE_URL + "user";
export const IMPORT_URL = BASE_URL + "import";
export const TRANSLATION_URL = BASE_URL + "translation";
export const PROMPT_URL = BASE_URL + "admin/chatgpt-prompt";
export const DATA_ENRICHMENT = BASE_URL + "data-enrichment/chatgpt-prompt";
export const DATA_ENRICHMENT_URL =
  BASE_URL + "data-enrichment/enrichments/enrich-product";
export const DATA_ENRICHMENT_WORKFLOW_URL =
  BASE_URL + "data-enrichment/workflow-enrichments";
export const DATA_ENRICHMENT_PRODUCT_URL =
  BASE_URL + "data-enrichment/enrichments/";
export const GOOGLE_SEARCH_PROMPT_URL =
  BASE_URL + "data-enrichment/google-search-prompt";
export const GLOSSARY_URL = BASE_URL + "glossary";
export const PRODUCT_URL = BASE_URL + "product";
export const CHATGPT_URL = BASE_URL + "chatgpt/send-message";
export const VARIATION_URL = PRODUCT_URL + "/variation";
export const COMPANY_URL = BASE_URL + "company";
export const OVERVIEW_URL = BASE_URL + "overview";
export const CLIENT_URL = BASE_URL + "client";
export const API_URL = BASE_URL + "client-profile-export";
export const CLIENT_MAPPING_URL = BASE_URL + "client-mapping";
export const CLIENT_EXPORT_URL = BASE_URL + "client-export";
export const DASHBOARD_URL = BASE_URL + "dashboard";
export const ATTRIBUTES_URL = BASE_URL + "admin/attribute";
export const VALUE_URL = BASE_URL + "admin/value";
export const CRAWLER_URL = BASE_URL + "admin/crawler";
export const CRAWLER_CONTROLLER = BASE_URL + "crawler";
export const CATEGORY_URL = BASE_URL + "admin/category";
export const CONTENT_URL = BASE_URL + "content";
export const WORKFLOW_URL = BASE_URL + "workflow";
export const IMPORT_MAPPING_URL = BASE_URL + "import-mapping/";
export const CONTENT_WORKFLOW_URL = BASE_URL + "workflow-content";
export const DATA_QUALITY_URL = BASE_URL + "data-quality";
export const BRAND_URL = BASE_URL + "brand";
export const ACCESS_RIGHTS_URL = BASE_URL + "admin/access-rights";
export const BLOCK_URL = BASE_URL + "block";
export const DOCUMENT_BLOCK_URL = BASE_URL + "document-block";
export const ENUMS_URL = BASE_URL + "enum";
export const RESET_PASSWORD_URL = BASE_URL + "forgot-password";
export const EXIT_URL = BASE_URL + "exit-page";
