import { t } from "i18next";
import { Button, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { IAllStates } from "../../../store/rootReducer";
import workflowSlice from "../../../store/workflow/workflowSlice";
import { WorkflowService } from "../service";
import Select from "react-select";
import { handleStepNavigate } from "./WorkflowStep2";
import { ProfileDetailsService } from "../../profile/service";
import authSlice from "../../../store/auth/authRedux";
const perPageOptions = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
  {
    label: 500,
    value: 500,
  },
];

export const WorkflowStepper = ({
  funcForFirstLoading,
  isDisabledComplete = true,
  precent,
  step,
  id,
  setSelectedProductId,
  changeFilterHandler,
  status,
  assigned,
  showMarkAsComplete = true,
  showNextButton = false,
  showFilters = true,
  handlePerPage,
  handleFilterCopywritingStatus,
  stepPagination,
}: any) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const copywritingStatusOptions = [
    {
      label: `${t("global.all")}`,
      value: null,
    },
    {
      label: `${t("global.finished")}`,
      value: "finished",
    },
    {
      label: `${t("global.unfinished")}`,
      value: "unfinished",
    },
  ];
  const { workflowStatus } = useSelector((state: IAllStates) => state.enums);
  const { baseData, attributes } = useSelector(
    (state: IAllStates) => state.workflow
  );
  const [tabsList, setTabsList] = useState([]);
  const handleNextProduct = () => {
    if (step === "BASE_DATA") {
      WorkflowService.getNextBaseDataWorkflow(Number(id))
        .then((response) => {
          if (!response.nextProduct?.id) {
            return navigate(`/app/workflow/step3/${id}`);
          } else {
            return navigate(
              `/app/workflow/step3/${response.nextProduct?.id}/${id}`
            );
          }
        })
        .catch((error) => ErrorToast(error));
    } else if (step === "ATTRIBUTES") {
      WorkflowService.getNextAttributesWorkflow(Number(id), step as string)
        .then((response) => {
          if (!response.nextProduct?.id) {
            return navigate(`/app/workflow/step4/${id}`);
          } else {
            return navigate(
              `/app/workflow/step4/${response.nextProduct?.id}/${id}`
            );
          }
        })
        .catch((error) => ErrorToast(error));
    } else if (step === "COPY_WRITING") {
      WorkflowService.getNextAttributesWorkflow(Number(id), step as string)
        .then((response) => {
          if (!response.nextProduct?.id) {
            return navigate(`/app/workflow/step5/${id}`);
          } else {
            return navigate(
              `/app/workflow/step5/${response.nextProduct?.id}/${id}`
            );
          }
        })
        .catch((error) => ErrorToast(error));
    } else {
      return;
    }
  };
  const handleMarkAsComplete = () => {
    if (step === "BASE_DATA") {
      WorkflowService.saveWorkflowBaseDataById(Number(id))
        .then((response) => {
          handleStepNavigate(response?.status, Number(id), navigate);
        })
        .catch((error) => ErrorToast(error));
    } else if (step === "ATTRIBUTES") {
      WorkflowService.saveWorkflowAttributesById(Number(id))
        .then((response) => {
          handleStepNavigate(response?.status, Number(id), navigate);
        })
        .catch((error) => ErrorToast(error));
    } else if (step === "COPY_WRITING") {
      WorkflowService.saveWorkflowCopywritingById(Number(id))
        .then((response) => {
          handleStepNavigate(response?.status, Number(id), navigate);
        })
        .catch((error) => ErrorToast(error));
    } else if (step === "TRANSLATION") {
      WorkflowService.saveWorkflowTranslationById(Number(id))
        .then((response) => {
          handleStepNavigate(response?.status, Number(id), navigate);
        })
        .catch((error) => ErrorToast(error));
    } else {
      return;
    }
  };
  const handleStepSteper = (status: string, id: number) => {
    switch (status) {
      case "SETTING_UP":
        return `/app/workflow/step1/${id}`;
      case "IMPORT":
        return `/app/workflow/step2/${id}`;
      case "BASE_DATA":
        return `/app/workflow/step3/${id}`;
      case "ATTRIBUTES":
        return `/app/workflow/step4/${id}`;
      case "COPY_WRITING":
        return `/app/workflow/step5/${id}`;
      case "TRANSLATION":
        return `/app/workflow/step6/${id}`;
      case "OVERVIEW":
        return `/app/workflow/step7/${id}`;
      case "EXPORT":
        return `/app/workflow/step8/${id}`;
      default:
        return `/app/workflow/step1/${id}`;
    }
  };

  useEffect(() => {
    Boolean(id) &&
      WorkflowService.getWorkflowById(id as any)
        .then((response: any) => {
          const glossaryClientObject = { client: response?.client?.name };
          const objectClient = JSON.stringify(glossaryClientObject);
          localStorage.setItem("glossaryClient", objectClient);
          step === "TRANSLATION" &&
            Boolean(funcForFirstLoading) &&
            funcForFirstLoading(response?.language[0]);
          setTabsList(response?.tabs);
          dispatch(workflowSlice.actions.setWorkflowData(response));
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
  }, [id, location?.pathname]);
  useEffect(() => {
    ProfileDetailsService.getProfileDetails()
      .then((data) => {
        dispatch(authSlice.actions.setAuth(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <Row>
      <Col md={12}>
        <ul className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
          {tabsList?.map((stepName: string) => {
            const removeBaseData = !baseData && stepName === "BASE_DATA"; // if we don't have base data and step is 3, don't render
            const removeAttributes = !attributes && stepName === "ATTRIBUTES"; // same logic as above
            if (removeBaseData || removeAttributes) {
              return;
            }
            return (
              <li className="nav-item" key={stepName}>
                <Link
                  className={`nav-link ${step === stepName ? "active" : ""}`}
                  to={handleStepSteper(stepName, Number(id))}
                >
                  {t(`workflow.${stepName}`)}
                </Link>
              </li>
            );
          })}
        </ul>
      </Col>
      {["BASE_DATA", "ATTRIBUTES", "COPY_WRITING", "TRANSLATION"].includes(
        step
      ) &&
        showFilters && (
          // setSelectedProductId
          <Col md={12} className="d-flex justify-content-between mb-2">
            {Boolean(changeFilterHandler) && (
              <div className="d-flex align-items-center">
                <span className="me-1">{t(`workflow.filter`)}:</span>
                <Button
                  variant={`${status === "" ? "" : "outline-"}primary`}
                  onClick={() => changeFilterHandler("status", "", true)}
                  className="d-flex align-items-center"
                  size="sm"
                >
                  {t("global.all")}
                </Button>
                <Button
                  variant={`${status === "finished" ? "" : "outline-"}success`}
                  onClick={() => changeFilterHandler("status", "finished")}
                  className="d-flex mx-2 align-items-center"
                  size="sm"
                >
                  {t("global.finished")}
                </Button>
                <Button
                  variant={`${status === "unfinished" ? "" : "outline-"}danger`}
                  onClick={() => changeFilterHandler("status", "unfinished")}
                  className="d-flex align-items-center"
                  size="sm"
                >
                  {t("global.unfinished")}
                </Button>
                {(assigned === "" || assigned) && (
                  <>
                    <Button
                      variant={`${
                        assigned === "yes" ? "" : "outline-"
                      }warning mx-2`}
                      onClick={() => changeFilterHandler("assigned", "yes")}
                      className="d-flex align-items-center"
                      size="sm"
                    >
                      {t("global.assigned")}
                    </Button>
                    <Button
                      variant={`${assigned === "no" ? "" : "outline-"}info`}
                      onClick={() => changeFilterHandler("assigned", "no")}
                      className="d-flex align-items-center"
                      size="sm"
                    >
                      {t("global.unassigned")}
                    </Button>
                  </>
                )}
                <h6 className="m-0 p-0 ms-2">{t("workflow.per_page")}:</h6>
                <Select
                  className="react-select-field ms-2"
                  onChange={handlePerPage}
                  placeholder={`${t("workflow.per_page")}:`}
                  options={perPageOptions}
                  defaultValue={
                    stepPagination?.perPage
                      ? {
                          label: stepPagination?.perPage,
                          value: stepPagination?.perPage,
                        }
                      : perPageOptions[0]
                  }
                />
                {step === "TRANSLATION" &&
                  (isManager || isAdmin || isCompanyAdmin) && (
                    <>
                      <h6 className="m-0 p-0 ms-2">
                        {t("workflow.copywriting_status")}:
                      </h6>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            minWidth: "150px",
                          }),
                        }}
                        className="react-select-field ms-2"
                        onChange={handleFilterCopywritingStatus}
                        placeholder={`${t("workflow.copywriting_status")}:`}
                        options={copywritingStatusOptions}
                        defaultValue={
                          stepPagination?.copywritingStatus
                            ? {
                                label: stepPagination?.copywritingStatus,
                                value: stepPagination?.copywritingStatus,
                              }
                            : copywritingStatusOptions[0]
                        }
                      />
                    </>
                  )}
              </div>
            )}
            <div className="d-flex align-items-center">
              {showMarkAsComplete && (
                <Button
                  variant="success"
                  onClick={
                    () => handleMarkAsComplete()
                    // navigate(`/app/workflow/step${step + 1}/${id}`)
                  }
                  className="d-flex align-items-center"
                  disabled={
                    step === "TRANSLATION"
                      ? !isDisabledComplete
                      : precent !== 100
                  }
                  size="sm"
                >
                  {t("global.markAsComplete")}
                </Button>
              )}
              {showNextButton && (
                <Button
                  variant="outline-success"
                  onClick={handleNextProduct}
                  className="ms-2"
                  disabled={precent === 100}
                  size="sm"
                >
                  {t("global.next")}
                  <i className="ri-arrow-right-line ms-1" />
                </Button>
              )}
            </div>{" "}
          </Col>
        )}
    </Row>
  );
};
